import {
    IntegrationType,
    SupportedIntegrationForOnboarding,
} from "graphql-types/graphql";

export const isIntegrationTypeValid = (
    integrationType: IntegrationType | null
): integrationType is IntegrationType => {
    const currentIntegrationType =
        integrationType?.toUpperCase() as SupportedIntegrationForOnboarding;

    const supportedIntegrations = Object.values(
        SupportedIntegrationForOnboarding
    );

    return supportedIntegrations.includes(currentIntegrationType);
};
