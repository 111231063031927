import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading/Loading";
import {
    GetAllAssetDetailsQuery,
    GetAllAssetDetailsQueryVariables,
} from "graphql-types/graphql";

import { AssetDetailsContent } from "./AssetDetailsContent";
import { ASSET_DETAILS_QUERY } from "./assetDetailsQuery";
import AssetMasterData from "./AssetMasterData";
import { ChangeLogModal } from "./modals";

type Props = {
    assetId: string;
};

const AssetDetails = (props: Props) => {
    const { assetId } = props;

    const { t } = useTranslation();

    const { data, loading } = useQuery<
        GetAllAssetDetailsQuery,
        GetAllAssetDetailsQueryVariables
    >(ASSET_DETAILS_QUERY, {
        variables: { locationId: assetId },
    });

    const [openChangeLog, setOpenChangeLog] = useState(false);

    const [asset, editsCount] = useMemo(
        () => [data?.location, data?.changeLogsFieldsCount ?? 0],
        [data]
    );

    if (loading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    if (!asset) {
        return (
            <Typography variant="h4" color="grey.400">
                {t(
                    "portfolioDashboard.sidePanel.buildingDetails.notFound",
                    "No building found with this National Building ID"
                )}
            </Typography>
        );
    }

    return (
        <Box
            sx={{
                gap: 4,
                display: "grid",
                gridTemplateColumns: "3fr 7fr",
            }}
        >
            <AssetMasterData
                asset={asset}
                editsCount={editsCount}
                onChangeLogOpenClick={() => setOpenChangeLog(true)}
            />

            <AssetDetailsContent asset={asset} />

            <ChangeLogModal
                isOpen={openChangeLog}
                assetId={asset.id}
                assetName={asset.displayName || "-"}
                editsCount={editsCount}
                handleClose={() => setOpenChangeLog(false)}
            />
        </Box>
    );
};

export default AssetDetails;
