import { Box, styled } from "@mui/material";

export const S = {
    Page: styled(Box)(() => ({
        height: "100vh",
        display: "flex",
        flexDirection: "column",
    })),

    Content: styled(Box)(({ theme }) => ({
        padding: 20,
        marginTop: 10,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        flexGrow: 1,
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    })),

    Filters: styled(Box)(() => ({
        marginTop: 20,
        marginBottom: 20,
    })),
};
