import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

import { S } from "./chartTooltip.styles";

type SectionItem = {
    label: string;
    value: string;
    iconColor?: string;
    textColor?: string;
};

type SubHeader = {
    label: string;
    value: string;
    iconColor?: string;
};

export type SectionProps = {
    header?: string | ReactNode;
    items?: SectionItem[];
    subHeaders?: SubHeader[];
};

const ChartTooltipSection = (props: SectionProps) => {
    const { header, subHeaders, items = [] } = props;

    const list = items
        .filter((item) => Boolean(item.value))
        .map((item, index) => {
            const { label, value, iconColor, textColor } = item;

            return (
                <Box
                    key={`${label}-${index}`}
                    display="flex"
                    alignItems="center"
                    gap={1}
                >
                    {iconColor && <S.Icon htmlColor={iconColor} />}
                    <Typography
                        color={textColor ? textColor : "text.secondary"}
                    >
                        {label}:
                    </Typography>
                    {value}
                </Box>
            );
        });

    return (
        <Box px={5}>
            <S.Header>{header}</S.Header>
            {subHeaders?.map(({ label, value, iconColor }, index) => (
                <Box key={index} gap={1}>
                    <Typography color="text.secondary">{label}:</Typography>
                    <S.Header>
                        {iconColor && <S.Icon htmlColor={iconColor} />}
                        {value}
                    </S.Header>
                </Box>
            ))}

            {list}
        </Box>
    );
};

export default ChartTooltipSection;
