import { TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DrawerField } from "./DrawerField";
import { useUpdateAssetIntegration } from "./updateAssetIntegration";

type Props = {
    id: string;
    note: string;
};

export const AllocationNoteField = (props: Props) => {
    const { id, note } = props;

    const { t } = useTranslation();

    const { updateAssetIntegration } = useUpdateAssetIntegration();

    const [value, setValue] = useState<string>(note);

    const handleSave = () => {
        if (value === note) {
            return;
        }

        const variables = {
            id,
            update: { allocationNote: value },
        };

        updateAssetIntegration({ variables });
    };

    return (
        <DrawerField
            label={t("asset.sourceDrawer.additionalNotes", "Additional Notes")}
            onSave={handleSave}
            onCancel={() => setValue(note)}
        >
            <TextField
                fullWidth
                value={value}
                size="small"
                variant="outlined"
                placeholder={t("labels.addNote", "Add note (Optional)")}
                onChange={(e) => setValue(e.target.value)}
            />
        </DrawerField>
    );
};
