import { Typography, TableRow, TableCell } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import FromToDatePicker from "components/DatePicker/FromToDatePicker";

import { AutocompleteCell } from "./AutocompleteCell";
import { IntegrationOption, LocationIntegrationInput } from "../../../types";

type Period = {
    from: DateTime | null;
    to: DateTime | null;
};

type Props<T extends IntegrationOption> = {
    displayName: string;
    integrations: T[];
    initialPeriod?: Period;
    selectAllOnInitialize?: boolean;
    onClose: (selected: LocationIntegrationInput<T>) => void;
};

export const AssetIntegrationMappingRow = <T extends IntegrationOption>(
    props: Props<T>
) => {
    const {
        displayName,
        integrations,
        initialPeriod,
        selectAllOnInitialize = false,
        onClose,
    } = props;

    const { t } = useTranslation();
    const [selected, setSelected] = useState<T[]>([]);

    const [period, setPeriod] = useState<Period>({
        from: initialPeriod?.from ?? null,
        to: initialPeriod?.to ?? null,
    });

    useEffect(() => {
        if (selectAllOnInitialize) {
            setSelected(integrations);
        }
    }, [selectAllOnInitialize, integrations]);

    const placeholder = integrations.length
        ? selected.length
            ? ""
            : t(
                  "integrationOnboarding.selectIntegrations",
                  "Select integrations"
              )
        : t("integrationOnboarding.noIntegrations", "No integrations");

    const handleOnClose = (updatedSelected?: T[], updatedPeriod?: Period) => {
        onClose({
            integrations: updatedSelected ?? selected,
            period: updatedPeriod ?? period,
        });
    };

    const value = useMemo(() => {
        const areAllSelectedValid = selected.every((integration) =>
            integrations.includes(integration)
        );

        if (areAllSelectedValid) {
            return selected;
        }

        setSelected([]);
        return [];
    }, [selected, integrations]);

    return (
        <TableRow>
            <TableCell>
                <Typography variant="h5">{displayName}</Typography>
            </TableCell>
            <AutocompleteCell<T>
                value={value}
                disabled={!integrations.length}
                options={integrations}
                placeholder={placeholder}
                handleSelectAll={() => setSelected(integrations)}
                onClose={() => handleOnClose()}
                onChange={(_, value, reason) => {
                    setSelected(value);

                    if (reason === "clear") {
                        handleOnClose([]);
                    }
                }}
            />
            <TableCell>
                <FromToDatePicker
                    disabled={!integrations.length}
                    from={period.from}
                    to={period.to}
                    onChange={(from, to) => {
                        const updatedPeriod = { from, to };
                        setPeriod(updatedPeriod);
                        handleOnClose(undefined, updatedPeriod);
                    }}
                />
            </TableCell>
        </TableRow>
    );
};
