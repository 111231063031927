import { gql } from "@apollo/client";

export const CREATE_SINGLE_LOCATION = gql`
    mutation CreateSingleLocation($location: CreateLocationInput!) {
        createOneLocation(input: { location: $location }) {
            id
            externalId
            integrations {
                id
                type
                originId
            }
            organization {
                id
                name
            }
        }
    }
`;

export const UPDATE_SINGLE_LOCATION = gql`
    mutation UpdateSingleLocation($input: UpdateOneLocationInput!) {
        updateOneLocation(input: $input) {
            name
            externalId
            buildingArea
            ownership
            propertyType
            purchaseDate
            saleDate
            legalOwner
            management
        }
    }
`;

export const CREATE_MANY_INTEGRATIONS = gql`
    mutation CreateManyIntegrations(
        $locationIntegrations: [CreateAssetIntegrationInput!]!
    ) {
        createManyLocationIntegrations(
            input: { locationIntegrations: $locationIntegrations }
        ) {
            id
            type
            originId
            data {
                ... on IntegrationEpcLocationIntegrationData {
                    municipality
                    property
                    building
                }
                ... on IntegrationDexmaLocationIntegrationData {
                    id
                    type
                }
            }
        }
    }
`;

export const GET_ORG_DATA_FOR_ONBOARDING = gql`
    query GetOrgDataForOnboarding {
        me {
            id
            organization {
                id
                name
                locations {
                    edges {
                        node {
                            id
                            externalId
                            displayName
                            name
                            shortAddress
                            internalDescriptor
                            integrations {
                                id
                                type
                                originId
                                data {
                                    ... on IntegrationDexmaLocationIntegrationData {
                                        dexmaId: id
                                        type
                                    }

                                    ... on IntegrationEpcLocationIntegrationData {
                                        municipality
                                        property
                                        building
                                    }

                                    ... on IntegrationVitaniLocationIntegrationData {
                                        directoryId
                                    }

                                    ... on IntegrationTechemLocationIntegrationData {
                                        propertyId
                                    }
                                    ... on IntegrationEpcUkLocationIntegrationData {
                                        buildingType
                                        address1
                                        address2
                                        address3
                                        postcode
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
