import { Autocomplete, TextField, Button } from "@mui/material";
import { useState, useEffect, useCallback, SyntheticEvent } from "react";

import { IntegrationType } from "graphql-types/graphql";

import { S } from "./onboarding-modal-styles";
import { useIntegrationCustomers } from "../../hooks";

type Props = {
    action: "add" | "update" | null;
    onAction: (data: Record<string, unknown>) => void;
    onClose: () => void;
};

export const OnboardingModal = (props: Props) => {
    const { action, onAction, onClose } = props;

    const [value, setValue] = useState<
        { value: string; label: string }[] | null
    >(null);

    const { fetchQuery, fetchData } = useIntegrationCustomers(
        IntegrationType.ELOVERBLIK // Only Eloverblik is supported
    );

    useEffect(() => {
        if (!fetchQuery) return;
        const [fetch] = fetchQuery;

        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = useCallback(() => {
        if (!value) return;

        onAction({ cvrs: value.map((v) => v.value) });
        onClose();
    }, [onAction, onClose, value]);

    const handleChange = (_: SyntheticEvent, value: typeof fetchData) => {
        setValue(value);
    };

    return (
        <S.Modal open={action !== null} onClose={onClose}>
            <S.ModalBody>
                <S.Header>Integration</S.Header>

                <S.Content>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={fetchData}
                        getOptionLabel={(option) => option.label}
                        onChange={handleChange}
                        isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Organizations"
                            />
                        )}
                    />

                    <Button variant="contained" onClick={handleClick}>
                        Add
                    </Button>
                </S.Content>
            </S.ModalBody>
        </S.Modal>
    );
};
