import { Box, Typography } from "@mui/material";
import { useMemo } from "react";

import { EditMappingState } from "containers/IntegrationOnboardingV2/types";

type Props = {
    assets: EditMappingState;
    operation: "update" | "delete";
};

export const ConfirmationMessage = (props: Props) => {
    const { assets, operation } = props;

    const messages = useMemo(
        () =>
            Object.values(assets)
                .filter((asset) => asset.integrations.length)
                .map((asset) => {
                    const { displayName, integrations, period } = asset;

                    const periodFrom =
                        period.from?.toFormat("dd/MM/yyyy") ?? "Null";
                    const periodTo =
                        period.to?.toFormat("dd/MM/yyyy") ?? "Null";

                    return (
                        <Box key={displayName}>
                            <Typography>
                                <b>{displayName}</b> - {integrations.length}{" "}
                                integrations will be {operation}d
                            </Typography>
                            {operation === "update" && (
                                <Typography>
                                    From: {periodFrom} - To: {periodTo}
                                </Typography>
                            )}
                        </Box>
                    );
                }),
        [assets, operation]
    );

    return <Box>{messages}</Box>;
};
