import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Button } from "glue/Button";
import { AllocationObjectType } from "graphql-types/graphql";

import { getStatusFilter } from "./automatic-source-toolbar.helpers";
import { AutomaticSourceFilter, Filter } from "./Filters";
import { DataSourceStatusFilter } from "../../types/dataSourceTypes";
import { QueryFilter } from "../automatic-source-table.hooks";

export const DEFAULT_STATUS_FILTER = [DataSourceStatusFilter.ACTIVE];
const allocationOptions = Object.values(AllocationObjectType);

type Props = {
    onDownload: () => void;

    // Query filter options
    count: number;
    queryFilter: QueryFilter;
    filterOptions: Pick<Filter, "consumptionTypes" | "integrationTypes">;
    onFilterChange: (key: keyof QueryFilter, value: any) => void;
};

const AutomaticSourceToolbar = (props: Props) => {
    const { filterOptions, onDownload, count, queryFilter, onFilterChange } =
        props;
    const { t } = useTranslation(["report"]);

    const onFilterChangeHandler = (key: keyof Filter, values: any) => {
        if (key === "source") {
            const value =
                values.length === 1
                    ? values[0] === "ACTIVE"
                    : values.length === 0
                    ? undefined
                    : null;

            onFilterChange("isActive", value);
            return;
        }

        onFilterChange(key, values);
    };

    const filter = {
        ...queryFilter,
        source: getStatusFilter(queryFilter.isActive),
    };

    const options = {
        source: Object.values(DataSourceStatusFilter),
        allocationTypes: allocationOptions,
        ...filterOptions,
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", pb: 3 }}>
            <AutomaticSourceFilter
                filters={filter}
                options={options}
                onFilterChange={onFilterChangeHandler}
            />

            <Box sx={{ display: "flex", gap: 5, alignItems: "center" }}>
                <Typography>
                    {count}{" "}
                    {t("pageheader.data", "Data Sources", {
                        ns: "translation",
                    })}
                </Typography>
                <Button variant="contained" onClick={onDownload}>
                    <Button.Label>
                        {t("common.download", "Download", {
                            ns: "translation",
                        })}
                    </Button.Label>
                </Button>
            </Box>
        </Box>
    );
};

export default AutomaticSourceToolbar;
