import { t } from "i18next";
import { capitalize } from "lodash";
import { match } from "ts-pattern";

import { DataSourceStatusFilter } from "../../types/dataSourceTypes";

export const getStatusFilter = (status: boolean | null | undefined) =>
    match(status)
        .with(true, () => [DataSourceStatusFilter.ACTIVE])
        .with(false, () => [DataSourceStatusFilter.EXPIRED])
        .with(null, () => [
            DataSourceStatusFilter.ACTIVE,
            DataSourceStatusFilter.EXPIRED,
        ])
        .with(undefined, () => [])
        .exhaustive();

export const getStatusTranslations = (status: DataSourceStatusFilter) =>
    match(status)
        .with(DataSourceStatusFilter.ACTIVE, () =>
            t("common.status.acitve", "Active", {
                ns: "translation",
            })
        )
        .with(DataSourceStatusFilter.EXPIRED, () =>
            t("common.status.expired", "Expired", {
                ns: "translation",
            })
        )
        .exhaustive();

export const getFilterRenderValues = <T extends string>(
    selectedValues: T[],
    allOptionsLength: number,
    formatting?: (value: T) => string
) => {
    if (selectedValues.length === allOptionsLength) {
        return t("common.all", "All", {
            ns: "translation",
        });
    }

    return selectedValues
        .map((value) =>
            formatting ? formatting(value) : capitalize(value).replace("_", " ")
        )
        .join(", ");
};
