import { Box } from "@mui/material";
import { useState } from "react";
import { match } from "ts-pattern";

import { IntegrationType } from "graphql-types/graphql";

import { isIntegrationTypeValid } from "./asset-integration-onboarding.helpers";
import { IntegrationHeader } from "./components";
import {
    AddSoftvaerketIntegration,
    AddElectralinkIntegration,
    AssetIntegrationMapping,
    AddEloverblikIntegration,
} from "../AssetIntegrationMapping";

type Props = {
    integrationType: IntegrationType | null;
    setActiveTab: (tab: IntegrationType | null) => void;
};

const AssetIntegrationOnboarding = (props: Props) => {
    const { integrationType, setActiveTab } = props;

    const [isEdit, setIsEdit] = useState(true);

    if (!isIntegrationTypeValid(integrationType)) {
        setActiveTab(null);

        return null;
    }

    return (
        <Box sx={{ position: "relative" }}>
            <IntegrationHeader
                title={integrationType}
                isEdit={isEdit}
                onEditChange={setIsEdit}
                setActiveTab={setActiveTab}
            />

            {isEdit ? (
                <AssetIntegrationMapping integrationType={integrationType} />
            ) : (
                match(integrationType)
                    .with(IntegrationType.ELECTRALINK, () => (
                        <AddElectralinkIntegration />
                    ))
                    .with(IntegrationType.SOFTVAERKET, () => (
                        <AddSoftvaerketIntegration />
                    ))
                    .with(IntegrationType.ELOVERBLIK, () => (
                        <AddEloverblikIntegration />
                    ))
                    .otherwise(() => null)
            )}
        </Box>
    );
};

export default AssetIntegrationOnboarding;
