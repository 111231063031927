import { getLabel, CommonSeriesType } from "@mui/x-charts/internals";

import { ChartTooltip } from "glue/Chart/components/ChartTooltip";

import { StrategyItemContentProps } from "../strategyPage.types";

export const StrategyTooltip = (props: StrategyItemContentProps) => {
    const { series, itemData, getColor, years } = props;

    if (!itemData.dataIndex || !series.data[itemData.dataIndex]) return null;

    const color = getColor(itemData.dataIndex);

    const value = series.data[itemData.dataIndex];
    const formattedValue = (
        series.valueFormatter as CommonSeriesType<
            typeof value
        >["valueFormatter"]
    )?.(value, { dataIndex: itemData.dataIndex });

    return (
        <ChartTooltip
            sections={[
                {
                    header: years?.[itemData.dataIndex]
                        .getFullYear()
                        .toString(),
                    subHeaders: [
                        {
                            label: "Intensity",
                            value: formattedValue ?? "Error",
                        },
                        {
                            label: "Asset Type",
                            value: getLabel(series.label, "tooltip") ?? "Error",
                            iconColor: color,
                        },
                    ],
                },
                {
                    items: [
                        {
                            label: "Internal Id",
                            value: series.internalId?.toString() || "",
                        },

                        {
                            label: "Construction Year",
                            value: series.constructionYear?.toString() || "",
                        },
                    ],
                },
            ]}
        />
    );
};
