import _ from "lodash";
import { DateTime } from "luxon";

import {
    Benchmark,
    AssessmentDataType,
    EmissionSummary,
    EmissionDetails,
    TargetEmissionDetails,
} from "graphql-types/graphql";

import { Location } from "../types";

export type AssessmentSummary = Location["assessmentSummaries"][0];
type ProjectedAssessmentSummary = Location["projectedAssessmentSummaries"][0];

const getAssetsWithDataType = (
    locations: Location[],
    type: AssessmentDataType
) => {
    return _.cloneDeep(locations).filter((l) =>
        l.assessmentSummaries.some((a) => hasAssessmentDataType(type, a))
    );
};

export function getTotalArea(
    locations: { ownedArea?: number | null }[] | null
) {
    return locations
        ? _.chain(locations)
              .map((l) => l.ownedArea)
              .sum()
              .value()
        : 0;
}

export const hasAssessmentDataType = (
    type: AssessmentDataType,
    a: AssessmentSummary | ProjectedAssessmentSummary
) => {
    switch (type) {
        case AssessmentDataType.ESTIMATE:
            return a.emissionDataGrams.emission.epc.ownedEmission !== null;
        default:
            return (
                a.emissionDataGrams.emission.bestEffort.ownedEmission !== null
            );
    }
};

export const getAssessmentTypeEmission = (
    type: AssessmentDataType,
    e: Omit<EmissionDetails | TargetEmissionDetails, "meter">
) => {
    switch (type) {
        case AssessmentDataType.ESTIMATE:
            return e.epc.ownedEmission;
        default:
            return e.bestEffort.ownedEmission;
    }
};

const getAssessmentDataTypeEmissionPerArea = (
    type: AssessmentDataType,
    a: AssessmentSummary | ProjectedAssessmentSummary
) => {
    switch (type) {
        case AssessmentDataType.ESTIMATE:
            return a.emissionDataGrams.emissionPerArea.epc.ownedEmission;
        default:
            return a.emissionDataGrams.emissionPerArea.bestEffort.ownedEmission;
    }
};

const getProjectedEmission = (
    locations: Location[],
    type: AssessmentDataType
) => {
    return _.chain(locations)
        .flatMap((l) => l.projectedAssessmentSummaries)
        .filter((p) => hasAssessmentDataType(type, p))
        .sumBy(
            (p) =>
                getAssessmentTypeEmission(type, p.emissionDataGrams.emission) ||
                0
        )
        .value();
};

const getProjectedEmissionPerArea = (
    locations: Location[],
    type: AssessmentDataType
) => {
    return _.chain(locations)
        .flatMap((l) => l.projectedAssessmentSummaries)
        .filter((p) => hasAssessmentDataType(type, p))
        .sumBy((p) => getAssessmentDataTypeEmissionPerArea(type, p) || 0)
        .value();
};

const filterAssessmentSummariesByYear = (
    locations: Location[],
    year: number,
    type: AssessmentDataType
) => {
    return _.chain(locations)
        .map((l) => {
            const filteredAssessmentSummaries = _.chain(l.assessmentSummaries)
                .filter(
                    (a) =>
                        DateTime.fromISO(a.from, { zone: "local" }).year ===
                            year && hasAssessmentDataType(type, a)
                )
                .value();

            if (filteredAssessmentSummaries.length) {
                return {
                    ...l,
                    assessmentSummaries: filteredAssessmentSummaries,
                };
            }
            return null;
        })
        .compact()
        .value();
};

export const getSummaryYearOverviewOnType = (
    locations: Location[],
    type: AssessmentDataType
) => {
    const filteredAssets = getAssetsWithDataType(locations, type);

    const summaryYearOverviews = _.chain(filteredAssets)
        .flatMap((l) => l.assessmentSummaries)
        .groupBy((a) => DateTime.fromISO(a.from, { zone: "local" }).year)
        .map((a) => {
            return {
                assessmentSummaries: a,
                emission: _.chain(a)
                    .sumBy(
                        (a) =>
                            getAssessmentTypeEmission(
                                type,
                                a.emissionDataGrams.emission
                            ) || 0
                    )
                    .value(),
                year: DateTime.fromISO(a[0].from, { zone: "local" }).year,
                dataType: type,
            };
        })
        .map((summaryYearOverview) => {
            const locationsWithFilteredAssessmentSummaries =
                filterAssessmentSummariesByYear(
                    filteredAssets,
                    summaryYearOverview.year,
                    type
                );
            const totalArea = getTotalArea(
                locationsWithFilteredAssessmentSummaries
            );
            const assetCount = locationsWithFilteredAssessmentSummaries.length;
            const projectedEmission = getProjectedEmission(
                filteredAssets,
                type
            );
            const projectedEmissionPerArea = getProjectedEmissionPerArea(
                filteredAssets,
                type
            );

            return {
                locations: locationsWithFilteredAssessmentSummaries,
                projectedEmission,
                projectedEmissionPerArea,
                assetCount,
                totalArea,
                ...summaryYearOverview,
            };
        })
        .value();

    return _.sortBy(summaryYearOverviews, ["year"]);
};

export const getBenchmark = (
    type: AssessmentDataType,
    benchmark: Benchmark
) => {
    switch (type) {
        case AssessmentDataType.ESTIMATE:
            return benchmark.emissionPerArea.epc.benchmark || null;
        case AssessmentDataType.ACTUAL:
            return benchmark.emissionPerArea.meter.benchmark || null;
        default:
            return benchmark.emissionPerArea.bestEffort.benchmark || null;
    }
};

export const getSumAssetsEmissionSummariesOwnedEmissions = (
    assetsEmissionSummaries: {
        emissionSummaries: Pick<EmissionSummary, "ownedEmission">[];
    }[]
) => {
    return _.chain(assetsEmissionSummaries)
        .flatMap((asset) => asset.emissionSummaries)
        .sumBy((es) => es?.ownedEmission || 0)
        .value();
};
