import { Alert, Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Logo from "assets/legacyLogoPaleBlueIconWithName.png";
import auth from "utils/auth";
import request from "utils/request";

import { ResetPasswordForm } from "./resetPassword.types";
import * as S from "../../containers/AuthPage/AuthPageMuiStyle";

export const ResetPassword = () => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState } = useForm<ResetPasswordForm>(
        {}
    );
    const [errorPopup, setErrorPopup] = useState(false);
    const [successPopup, setSuccessPopup] = useState(false);

    const sendResetRequest = async (body: ResetPasswordForm) => {
        const endpointUrl = auth.getEndpointUrl();
        const requestUrl = `${endpointUrl}/auth/reset-password`;

        try {
            await request(requestUrl, { method: "POST", body });
            setSuccessPopup(true);
            setErrorPopup(false);
        } catch (error) {
            setErrorPopup(true);
            setSuccessPopup(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(sendResetRequest)}>
            <S.FullSizeContainer>
                <S.BackContainer>
                    <S.ImageContainer>
                        <S.ImageBox variant="square" src={Logo} alt="logo" />
                    </S.ImageContainer>

                    <S.LoginContainer>
                        <Typography variant="h2" gutterBottom>
                            {t("auth.reset.resetHeader", "Reset password")}
                        </Typography>
                        <S.TextTypo>Email</S.TextTypo>
                        <S.InputText
                            {...register("email", {
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: t(
                                        "auth.reset.emailValidation",
                                        "Invalid email adress"
                                    ),
                                },
                            })}
                            name="email"
                            fullWidth
                            required
                        />
                        {formState.errors.email && (
                            <Alert severity="error">
                                {formState.errors.email.message}
                            </Alert>
                        )}
                        {errorPopup && (
                            <Alert severity="error">
                                {t(
                                    "auth.reset.error",
                                    "We could not find any user connected to that email. Verify the email or contact support@wearelegacy.dk if it still does not work."
                                )}
                            </Alert>
                        )}
                        {successPopup && (
                            <Alert variant="outlined" severity="success">
                                {t(
                                    "auth.reset.success",
                                    "We have sent you an email with a link to reset your password."
                                )}
                            </Alert>
                        )}
                        <Box display="flex" gap={5}>
                            <Button
                                sx={{ width: "100%" }}
                                component={Link}
                                to="/auth/login"
                                variant="outlined"
                            >
                                {t("common.actions.cancel", "Cancel")}
                            </Button>
                            <Button
                                sx={{ width: "100%" }}
                                disabled={
                                    successPopup || formState.isSubmitting
                                }
                                type="submit"
                                variant="contained"
                            >
                                {t("auth.reset.button", "Reset password")}
                            </Button>
                        </Box>
                    </S.LoginContainer>
                </S.BackContainer>
            </S.FullSizeContainer>
        </form>
    );
};
