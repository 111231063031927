import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

interface SearchFilterProps {
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    inputValue: string;
}

function SearchFilter(props: SearchFilterProps) {
    const { onChange, inputValue } = props;

    const { t } = useTranslation();

    return (
        <TextField
            sx={{ minWidth: "200px", maxWidth: "450px", width: "100%" }}
            size="small"
            value={inputValue}
            onChange={onChange}
            variant="outlined"
            placeholder={t(
                "assessmentOverview.assetList.filter.searchPlaceholder",
                "Search Asset or ID"
            )}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {<SearchIcon />}
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default SearchFilter;
