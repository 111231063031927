import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading/Loading";
import {
    CountryCode,
    GetAssetPropertyTreeQuery,
    GetAssetPropertyTreeQueryVariables,
    Property,
} from "graphql-types/graphql";

import { flatPropertyTree } from "./asset-property-list.helper";
import { S } from "./asset-property-list.styles";
import { EventAction, PropertyFlatTree } from "./asset-property-list.types";
import { AssetPropertyItem } from "./AssetPropertyItem";
import { RemovePropertyDialog } from "../../dialogs";
import { PropertyModal } from "../../modals";
import { GET_ASSET_PROPERTY_TREE } from "../../queries";
import { ModalProperty } from "../../types";
import { AssetPropertyDrawer } from "../AssetPropertyDrawer";

type Props = {
    assetId: string;
    countryCode: CountryCode;
    onItemDelete: (itemId: string, assetId: string) => void;
    onItemAdd: (item: ModalProperty, parentId: string) => void;
    onItemEdit: (item: Partial<ModalProperty>, itemId: string) => void;
    areAllocationIconsShown: boolean;
};

export const AssetPropertyList = (props: Props) => {
    const {
        assetId,
        countryCode,
        onItemDelete,
        onItemAdd,
        onItemEdit,
        areAllocationIconsShown,
    } = props;

    const { t } = useTranslation();

    const [selectedProperty, setSelectedProperty] = useState<Property | null>(
        null
    );
    const [action, setAction] = useState<EventAction | null>(null);

    const { data, loading } = useQuery<
        GetAssetPropertyTreeQuery,
        GetAssetPropertyTreeQueryVariables
    >(GET_ASSET_PROPERTY_TREE, {
        variables: { assetId },
    });

    const handleEvents = (action: EventAction, item: PropertyFlatTree) => {
        setSelectedProperty(item);
        setAction(action);
    };

    const onCloseEvent = () => {
        setAction(null);
    };

    function onConfirmRemoveProperty(item: Property | null) {
        if (item) {
            onItemDelete(item.id, assetId);
        }

        onCloseEvent();
    }

    function onConfirmAddProperty(
        property: ModalProperty,
        item: Property | null | undefined
    ) {
        if (item) {
            onItemAdd(property, item.id);
        }

        onCloseEvent();
    }

    function onConfirmEditProperty(property: Partial<ModalProperty>) {
        onItemEdit(property, selectedProperty?.id as string);
        onCloseEvent();
    }

    const items = data?.getAssetPropertyTree || [];

    if (loading) {
        return <Loading description={t("labels.loading", "Loading")} />;
    }

    if (!items) {
        return null;
    }

    const flatPropertyList = flatPropertyTree(items as Property[]);
    const list = flatPropertyList.map((item) => (
        <AssetPropertyList.Item
            key={item.id}
            item={item}
            onTriggerEvent={handleEvents}
            areAllocationIconsShown={areAllocationIconsShown}
        />
    ));

    return (
        <>
            <S.List>{list}</S.List>
            {selectedProperty && (
                <AssetPropertyDrawer
                    property={selectedProperty}
                    isOpen={action === "select"}
                    onClose={onCloseEvent}
                />
            )}

            <RemovePropertyDialog
                assetProperty={selectedProperty}
                isOpen={action === "remove"}
                onClose={onCloseEvent}
                onConfirm={onConfirmRemoveProperty}
            />
            <PropertyModal
                parentAssetProperty={selectedProperty}
                parentName={selectedProperty?.name}
                countryCode={countryCode}
                isOpen={action === "add" || action === "edit"}
                onClose={onCloseEvent}
                isEditMode={action === "edit"}
                onConfirmAddProperty={onConfirmAddProperty}
                onConfirmEditProperty={onConfirmEditProperty}
                propertyId={selectedProperty?.id}
            />
        </>
    );
};

AssetPropertyList.Item = AssetPropertyItem;
