import { Box, Stack, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "glue/Button";
import { GetAllAssetDetailsQuery, CountryCode } from "graphql-types/graphql";

import { AssetPropertyList } from "./components";
import { AssetPropertyAllocationCount } from "./components/AssetPropertyAllocationCount";
import { PropertyModal } from "./modals";
import { usePropertyMutations } from "./queries/usePropertyMutations";

type Props = {
    asset: GetAllAssetDetailsQuery["location"];
};

export const AssetDetailsContent = (props: Props) => {
    const { asset } = props;

    const { t } = useTranslation();
    const { useConsumptionAllocation } = useFlags();

    const [openAddPropertyModal, setOpenAddPropertyModal] = useState(false);

    const { onSaveProperty, onEditProperty, onPropertyDelete } =
        usePropertyMutations(asset.id);

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" mb={4}>
                <Typography variant="h3" fontWeight={700}>
                    {t(
                        "assetDetailsComponent.propertyList.title",
                        "Properties"
                    )}
                </Typography>
                <Box justifyContent="flex-end">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setOpenAddPropertyModal(true)}
                    >
                        <Button.Label>
                            {t(
                                "assetDetailsComponent.addProperty",
                                "add property"
                            )}
                        </Button.Label>
                    </Button>
                </Box>
            </Stack>
            <AssetPropertyList
                countryCode={asset.countryCode as CountryCode}
                assetId={asset.id}
                onItemDelete={onPropertyDelete}
                onItemAdd={onSaveProperty}
                onItemEdit={onEditProperty}
                areAllocationIconsShown={useConsumptionAllocation}
            />
            {useConsumptionAllocation && (
                <AssetPropertyAllocationCount assetId={asset.id} />
            )}

            <PropertyModal
                parentName={asset?.displayName}
                countryCode={asset.countryCode as CountryCode}
                isOpen={openAddPropertyModal}
                onConfirmAddProperty={(property) => onSaveProperty(property)}
                onClose={() => setOpenAddPropertyModal(false)}
            />
        </Box>
    );
};
