import { Alert, Box, Button, Typography } from "@mui/material";
import { t } from "i18next";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";

import Logo from "assets/legacyLogoPaleBlueIconWithName.png";
import Loading from "components/Loading/Loading";
import auth from "utils/auth";
import request from "utils/request";

import { SetPasswordForm, SetPasswordFormState } from "./setPassword.types";
import * as S from "../../containers/AuthPage/AuthPageMuiStyle";

export const SetPassword = () => {
    const [searchParams] = useSearchParams();
    const [flowState, setFlowState] = useState<SetPasswordFormState>(
        SetPasswordFormState.LOADING
    );

    const token = searchParams.get("token");
    const endpointUrl = auth.getEndpointUrl();

    const { register, watch, handleSubmit, formState } =
        useForm<SetPasswordForm>();

    useEffect(() => {
        if (!token || !endpointUrl)
            return setFlowState(SetPasswordFormState.NOT_VALIDATED);

        const validateToken = async () => {
            try {
                await request(`${endpointUrl}/auth/validate-token`, {
                    method: "POST",
                    body: { token },
                });

                setFlowState(SetPasswordFormState.VALIDATED);
            } catch (err) {
                setFlowState(SetPasswordFormState.NOT_VALIDATED);
            }
        };
        validateToken();
    }, [endpointUrl, token]);

    if (flowState === SetPasswordFormState.LOADING) {
        return (
            <div
                style={{
                    display: "flex",
                    height: "100vh",
                    justifyContent: "center",
                }}
            >
                <Loading description={t("labels.loading", "Loading...")} />
            </div>
        );
    }

    if (flowState === SetPasswordFormState.NOT_VALIDATED) {
        return (
            <S.FullSizeContainer>
                <S.BackContainer>
                    <S.ImageContainer>
                        <S.ImageBox variant="square" src={Logo} alt="logo" />
                    </S.ImageContainer>

                    <S.LoginContainer>
                        <Alert severity="error">
                            {t(
                                "auth.reset.expiredToken",
                                "Reset link has expired, try resetting password again."
                            )}
                        </Alert>
                        <Button
                            component={Link}
                            to="/auth/login"
                            variant="contained"
                        >
                            {t("common.actions.cancel", "Cancel")}
                        </Button>
                    </S.LoginContainer>
                </S.BackContainer>
            </S.FullSizeContainer>
        );
    }

    const sendUpdatePasswordRequest = async ({ password }: SetPasswordForm) => {
        try {
            await request(`${endpointUrl}/auth/update-password`, {
                method: "POST",
                body: { token, password },
            });
            setFlowState(SetPasswordFormState.SUCCESS);
        } catch (error) {
            setFlowState(SetPasswordFormState.ERROR);
        }
    };

    return (
        <form onSubmit={handleSubmit(sendUpdatePasswordRequest)}>
            <S.FullSizeContainer>
                <S.BackContainer>
                    <S.ImageContainer>
                        <S.ImageBox variant="square" src={Logo} alt="logo" />
                    </S.ImageContainer>

                    <S.LoginContainer>
                        <Typography variant="h2" gutterBottom>
                            {t("auth.reset.setHeader")}
                        </Typography>

                        <S.TextTypo>
                            {t("auth.reset.newPassword", "New password")}
                        </S.TextTypo>
                        <S.InputText
                            {...register("password", {
                                required: true,
                            })}
                            type="password"
                            autoComplete="new-password"
                            fullWidth
                            required
                        />
                        <S.TextTypo>
                            {t(
                                "auth.reset.confirmPassword",
                                "Confirm password"
                            )}
                        </S.TextTypo>
                        <S.InputText
                            {...register("confirmPassword", {
                                required: true,
                                validate: (val: string) => {
                                    if (watch("password") !== val) {
                                        return t(
                                            "auth.reset.mismatchingPasswords",
                                            "Your passwords does not match"
                                        );
                                    }
                                },
                            })}
                            type="password"
                            autoComplete="new-password"
                            fullWidth
                            required
                        />

                        {flowState === SetPasswordFormState.ERROR && (
                            <Alert severity="error">
                                {t("common.toast.error")}
                            </Alert>
                        )}

                        {flowState !== SetPasswordFormState.SUCCESS ? (
                            <Box display="flex" gap={5}>
                                <Button
                                    sx={{ width: "100%" }}
                                    component={Link}
                                    to="/auth/login"
                                    variant="outlined"
                                >
                                    {t("common.actions.cancel", "Cancel")}
                                </Button>
                                <Button
                                    sx={{ width: "100%" }}
                                    disabled={
                                        formState.isSubmitting ||
                                        !formState.isValid
                                    }
                                    type="submit"
                                    variant="contained"
                                >
                                    {t(
                                        "auth.reset.updatePassword",
                                        "Update password"
                                    )}
                                </Button>
                            </Box>
                        ) : (
                            <>
                                <Alert
                                    severity="success"
                                    sx={{ marginBottom: 3 }}
                                >
                                    {t("auth.reset.passwordUpdated")}
                                </Alert>
                                <Button
                                    component={Link}
                                    to="/auth/login"
                                    variant="contained"
                                >
                                    {t("auth.login", "Login")}
                                </Button>
                            </>
                        )}
                    </S.LoginContainer>
                </S.BackContainer>
            </S.FullSizeContainer>
        </form>
    );
};
