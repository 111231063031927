import {
    AutocompleteRenderOptionState,
    Checkbox,
    Typography,
    AutocompleteRenderInputParams,
    TextField,
    TableCell,
    Autocomplete,
    Box,
    Button,
    Divider,
    Paper,
    AutocompleteProps,
} from "@mui/material";
import { useCallback, HTMLAttributes } from "react";
import { Trans } from "react-i18next";

import { IntegrationOption } from "../../../types";

const DropdownPaper = ({
    children,
    handleSelectAll,
    ...restPaperProps
}: HTMLAttributes<HTMLElement> & {
    handleSelectAll: () => void;
}) => (
    <Paper {...restPaperProps}>
        <Box onMouseDown={(e) => e.preventDefault()}>
            <Button fullWidth onClick={handleSelectAll}>
                <Trans i18nKey="integrationOnboarding.selectAll">
                    Select All
                </Trans>
            </Button>
        </Box>
        <Divider />
        {children}
    </Paper>
);

export const AutocompleteCell = <T extends IntegrationOption>(
    props: Omit<AutocompleteProps<T, true, false, false>, "renderInput"> & {
        placeholder?: string;
        handleSelectAll: () => void;
    }
) => {
    const { handleSelectAll, ...autocompleteProps } = props;

    const renderOption = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: any,
        { selected }: AutocompleteRenderOptionState
    ) => (
        <li
            {...props}
            key={option.value}
            style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 0,
            }}
        >
            <Checkbox size="small" checked={selected} />
            <Typography variant="body2">{option.label}</Typography>
        </li>
    );

    const renderInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            size="small"
            variant="outlined"
            placeholder={props.placeholder}
        />
    );

    const renderTag = (value: any[]) => {
        const headValues = value
            .slice(0, 2)
            .map(({ label }) => label.split(" - ")[1])
            .join(", ");

        const restCounter = value.length > 2 && ` ( +${value.length - 2})`;
        return (
            <Typography>
                {headValues}
                {restCounter}
            </Typography>
        );
    };

    const PaperComponent = useCallback(
        (props: HTMLAttributes<HTMLElement>) => (
            <DropdownPaper {...props} handleSelectAll={handleSelectAll} />
        ),
        [handleSelectAll]
    );

    return (
        <TableCell sx={{ display: "flex" }}>
            <Autocomplete<T, true>
                multiple
                fullWidth
                loading={true}
                PaperComponent={PaperComponent}
                renderInput={renderInput}
                isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                }
                renderOption={renderOption}
                renderTags={renderTag}
                disableCloseOnSelect
                {...autocompleteProps}
            />
        </TableCell>
    );
};
