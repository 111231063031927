import { gql } from "@apollo/client";

export const ASSET_DETAILS_QUERY = gql`
    query GetAllAssetDetails(
        $locationId: ID!
        $eventType: ChangeLogEventType = ASSET_UPDATED
    ) {
        changeLogsFieldsCount(recordId: $locationId, eventType: $eventType)

        location(id: $locationId) {
            id
            displayName
            externalId
            constructionDate
            countryCode
            propertyCount
            ownership
            purchaseDate
            saleDate
            management
            legalOwner
            buildingArea
            propertyType
            ownedArea
        }
    }
`;
