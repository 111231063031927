import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { TFunction } from "i18next";

import { TableHeader } from "components/Table";

export const getColumns = (t: TFunction): GridColDef[] => {
    return [
        {
            flex: 2,
            field: "displayName",
            headerName: "Asset Name",
            renderHeader: (params: any) =>
                TableHeader({
                    params,
                    subHeaderText: t(
                        "portfolioDashboard.locationTable.subheader.names",
                        "Address or Name of building"
                    ),
                }),
        },
        {
            field: "externalId",
            headerName: t("portfolioDashboard.locationTable.Id", "ID"),
            renderHeader: (params: GridColumnHeaderParams) =>
                TableHeader({
                    params,
                }),
            flex: 0.8,
        },
        {
            field: "ownedArea",
            headerName: t("portfolioDashboard.locationTable.area", "Area"),
            renderHeader: (params) =>
                TableHeader({
                    params,
                    subHeaderText: t("common.units.area.m2", "m²"),
                }),
            flex: 0.9,
        },
    ];
};
