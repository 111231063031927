import { Grid2 } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import Loading from "components/Loading/Loading";
import { IntegrationType } from "graphql-types/graphql";

import {
    OnboardingModal,
    IntegrationLink,
    ENABLED_INTEGRATIONS,
} from "./components";
import { useOrganizationIntegration } from "./hooks";

type Props = {
    setActiveTab: (tab: IntegrationType | null) => void;
};

const OrganizationIntegrationOnboarding = (props: Props) => {
    const { setActiveTab } = props;

    const { t } = useTranslation();
    const { useSoftvaerketIntegration, useElovebliktIntegration } = useFlags();

    const [modalAction, setModalAction] = useState<"add" | "update" | null>(
        null
    );

    const { add, update, integrations, loading } = useOrganizationIntegration();

    const handleModalAction = (
        type: IntegrationType,
        data?: Record<string, unknown>
    ) => {
        match(modalAction)
            .with("add", () => add(type, data))
            .with("update", () => update(type, data));

        setModalAction(null);
    };

    const handleClick = (type: IntegrationType, updateMeters: boolean) => {
        if (updateMeters && integrations.includes(type))
            return setActiveTab(type);

        if (type === IntegrationType.ELOVERBLIK) {
            return setModalAction(
                integrations.includes(type) ? "update" : "add"
            );
        }

        add(type);
    };

    const activeIntegrations = ENABLED_INTEGRATIONS.filter((integration) =>
        integration === IntegrationType.ELOVERBLIK
            ? useElovebliktIntegration
            : integration === IntegrationType.SOFTVAERKET
            ? useSoftvaerketIntegration
            : true
    );

    if (loading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    return (
        <Grid2 container spacing={4}>
            {activeIntegrations.map((integration) => (
                <Grid2 key={integration} size={6}>
                    <IntegrationLink
                        onClick={handleClick}
                        integration={integration}
                        isActive={integrations.includes(integration)}
                    />
                </Grid2>
            ))}

            <OnboardingModal
                action={modalAction}
                onAction={(data) =>
                    handleModalAction(IntegrationType.ELOVERBLIK, data)
                }
                onClose={() => setModalAction(null)}
            />
        </Grid2>
    );
};

export default OrganizationIntegrationOnboarding;
