import { SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { IntegrationType } from "graphql-types/graphql";
import { DASH_SEPARATOR } from "utils/strings.helpers";

import { S } from "./asset-integration-mapping-table.styles";
import { AssetIntegrationMappingRow } from "./AssetIntegrationMappingRow";
import {
    IntegrationOption,
    LocationIntegrationRowType,
    LocationIntegrationState,
} from "../../../types";

type Props<
    I extends IntegrationOption,
    L extends LocationIntegrationRowType<I>
> = {
    locations: L[];
    isLoading: boolean;
    selectAllOnInitialize?: boolean;
    integrationType: IntegrationType;
    setLocationIntegrations: (
        value: SetStateAction<LocationIntegrationState<I>>
    ) => void;
};

export const AssetIntegrationMappingTable = <
    I extends IntegrationOption,
    L extends LocationIntegrationRowType<I>
>(
    props: Props<I, L>
) => {
    const {
        locations,
        integrationType,
        setLocationIntegrations,
        selectAllOnInitialize = false,
    } = props;

    const { t } = useTranslation();

    if (props.isLoading) {
        return <S.Loading />;
    }

    if (!locations.length) {
        return (
            <S.NoIntegrationRow>
                {t(
                    "integrationOnboarding.noMeterLocationsLabel",
                    "No location found in {{integrationType}}",
                    { integrationType }
                )}
            </S.NoIntegrationRow>
        );
    }

    return (
        <S.MapTable>
            {locations.map(({ id, displayName, integrations }) => (
                <AssetIntegrationMappingRow
                    key={id}
                    displayName={displayName ?? DASH_SEPARATOR}
                    integrations={integrations}
                    selectAllOnInitialize={selectAllOnInitialize}
                    onClose={(selected) =>
                        setLocationIntegrations((prev) => ({
                            ...prev,
                            [id]: {
                                displayName,
                                ...selected,
                            },
                        }))
                    }
                />
            ))}
        </S.MapTable>
    );
};
