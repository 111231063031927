import { Typography } from "@mui/material";

import { Chart } from "./components";
import { Filters } from "./components/Filters";
import { S } from "./page.styles";

export const StrategyPage = () => {
    return (
        <S.Page height="100vh" display="flex" flexDirection="column">
            <Typography variant="h1">SBTi decarbonisation path</Typography>
            <Typography variant="subtitle1">
                Get a full overview of whether or not your full portfolio and
                individual assets are on track when it comes to reduction plans.
            </Typography>
            <S.Content>
                <Typography variant="h4">Portfolio path overview</Typography>
                <Typography variant="body1">
                    Pick the relevant assets and compare their path to the CRREM
                    and National Legacy benchmarks to see if they are performing
                    well enough or if they are at risk of stranding — download
                    the graph, and use the path overview to guide your strategic
                    decision making.
                </Typography>
                <S.Filters>
                    <Filters />
                </S.Filters>
                <Chart />
            </S.Content>
        </S.Page>
    );
};
