import { FilterItemOption, FilterValue } from "components/AssetsGroup/types";
import { AssetGroupFilterCategory, PropertyType } from "graphql-types/graphql";
import { useAssetFilterTranslations } from "hooks";

import { FilterOptionSelect } from "../OptionLayout";

type Props = {
    onChange: (filter: FilterItemOption) => void;
};

export function FilterOptionGeneralProperty(props: Props) {
    const { onChange } = props;

    const { translations } = useAssetFilterTranslations();

    const propertyTypeList = Object.keys(PropertyType).map(
        (propertyTypeKey) => {
            return {
                key: propertyTypeKey,
                value: translations[propertyTypeKey as PropertyType],
            };
        }
    );

    function onSelectedValue(value: FilterValue) {
        onChange({
            category: AssetGroupFilterCategory.GENERALPROPERTYTYPES,
            values: [value],
        });
    }

    return (
        <FilterOptionSelect
            list={propertyTypeList}
            onSelectedValue={onSelectedValue}
        />
    );
}
