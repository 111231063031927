import {
    AnimatedLine,
    AnimatedLineProps,
    LineChart,
    LineChartProps,
    LineSeriesType,
    useChartId,
    useDrawingArea,
    useXScale,
} from "@mui/x-charts";
import { DateTime } from "luxon";
import React from "react";

import { StrategyTooltip } from "./StrategyTooltip";

interface CustomAnimatedLineProps extends AnimatedLineProps {
    limit?: Date;
}

function CustomAnimatedLine(props: CustomAnimatedLineProps) {
    const { limit, ...other } = props;
    const { ownerState } = other;
    const { top, bottom, height, left, width } = useDrawingArea();
    const scale = useXScale();
    const chartId = useChartId();

    if (
        limit === undefined ||
        ownerState.id.toString().startsWith("benchmark")
    ) {
        return <AnimatedLine {...other} />;
    }

    const limitPosition = scale(limit);

    if (limitPosition === undefined) {
        return <AnimatedLine {...other} />;
    }

    const clipIdleft = `${chartId}-${props.ownerState.id}-line-limit-${limit}-1`;
    const clipIdRight = `${chartId}-${props.ownerState.id}-line-limit-${limit}-2`;
    return (
        <React.Fragment>
            {/* Clip to show the line before the limit */}
            <clipPath id={clipIdleft}>
                <rect
                    x={left}
                    y={0}
                    width={limitPosition - left}
                    height={top + height + bottom}
                />
            </clipPath>
            {/* Clip to show the line after the limit */}
            <clipPath id={clipIdRight}>
                <rect
                    x={limitPosition}
                    y={0}
                    width={left + width - limitPosition}
                    height={top + height + bottom}
                />
            </clipPath>
            <g clipPath={`url(#${clipIdleft})`} className="line-before">
                <AnimatedLine {...other} />
            </g>
            <g clipPath={`url(#${clipIdRight})`} className="line-after">
                <AnimatedLine {...other} />
            </g>
        </React.Fragment>
    );
}

const years = Array.from({ length: 11 }, (_, i) => new Date(2020 + i, 0));
const assetOne = {
    data: [null, null, 17, 16, 14, 13, 15, 16, 15],
    constructionYear: 2002,
    internalId: 23,
};
const assetTwo = {
    data: [null, 8, 8, 7, 6, 5, 4, 5, 6, 7],
    constructionYear: 1970,
    internalId: 55,
};
const ccremPathway = [18, null, null, null, null, null, null, null, null, 5];

export type StrategySeriesType = {
    internalId?: number;
    constructionYear?: number;
} & LineSeriesType;

const kwhFormatter = (v: number | null) => (v === null ? "" : `${v} kwh / m2`);

const series: StrategySeriesType[] = [
    {
        ...assetOne,
        type: "line",
        label: "Office & Administration",
        curve: "linear",
        color: "green",
        data: assetOne.data,
        valueFormatter: kwhFormatter,
    },
    {
        ...assetTwo,
        type: "line",
        label: "Office & Administration",
        curve: "linear",
        color: "green",
        data: assetTwo.data,
        valueFormatter: kwhFormatter,
    },
    {
        type: "line",
        label: "CRREM Pathway",
        id: "benchmark-ccrem",
        curve: "linear",
        data: ccremPathway,
        connectNulls: true,
        showMark: false,
    },
];

const yearFormatter = (date: Date) => date.getFullYear().toString();

const lineChartsParams: LineChartProps = {
    series,
    slots: {
        itemContent: StrategyTooltip,
        line: CustomAnimatedLine,
    },
    slotProps: {
        line: { limit: DateTime.now().startOf("year") } as any,
        // Is as any to figure out types later
        itemContent: { years } as any,
    },
    height: 500,
    yAxis: [{ label: "KW/h", tickNumber: 5, labelStyle: { translate: -10 } }],
    xAxis: [
        {
            data: years,
            scaleType: "time",
            valueFormatter: yearFormatter,
        },
    ],
    tooltip: {
        trigger: "item",
    },
    sx: {
        "& .line-after path": { strokeDasharray: "10 5", stroke: "gray" },
        "& .line-after .MuiMarkElement-root": { stroke: "gray" },
        "& .MuiMarkElement-root": { cursor: "pointer" },
        "& .MuiMarkElement-root:hover": { strokeWidth: 4 },
    },
    axisHighlight: { x: "none", y: "none" },
};

export const Chart = () => {
    return <LineChart {...lineChartsParams} />;
};
