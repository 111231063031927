import { Typography } from "@mui/material";
import React, { cloneElement, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";

import InfoPopover from "components/Popovers/InfoPopover";
import { Button } from "glue/Button";

import { S } from "./drawerFields.style";

type Props = {
    label: string;
    tooltip?: string;
    isSaveEnabled?: boolean;
    onSave: () => void;
    onCancel: () => void;
};

export const DrawerField = (props: PropsWithChildren<Props>) => {
    const {
        label,
        onSave,
        onCancel,
        tooltip,
        children,
        isSaveEnabled = true,
    } = props;

    const [isEdit, setIsEdit] = useState(false);

    const { t } = useTranslation();

    const handleOnCancel = () => {
        onCancel();
        setIsEdit(false);
    };

    const handleOnClick = () => {
        if (isEdit) {
            onSave();
        }

        setIsEdit(!isEdit);
    };

    return (
        <S.Box>
            <S.Header>
                <Typography variant="h4">{label}</Typography>
                {tooltip && <InfoPopover element={tooltip} />}
            </S.Header>

            <S.ButtonBox>
                {isEdit && (
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={handleOnCancel}
                    >
                        <Button.Label>
                            {t("common.actions.cancel", "Cancel")}
                        </Button.Label>
                    </Button>
                )}
                <Button
                    size="small"
                    variant="contained"
                    onClick={handleOnClick}
                    disabled={isEdit && !isSaveEnabled}
                >
                    <Button.Label>
                        {isEdit
                            ? t("common.actions.save", "Save")
                            : t("common.actions.edit", "Edit")}
                    </Button.Label>
                </Button>
            </S.ButtonBox>

            {cloneElement(children as React.ReactElement, {
                disabled: !isEdit,
            })}
        </S.Box>
    );
};
