import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";

import { AssetGroupFilterCategory } from "graphql-types/graphql";
import { useAssetFilterTranslations } from "hooks";
import { FilterItemOption, FilterValue } from "utils";

import { MenuProps } from "./filters.styles";

const currentYear = new Date().getFullYear();

const list = [
    { key: `2020-${currentYear}`, value: `2020 - now` },
    { key: "2015-2019", value: "2015 - 2019" },
    { key: "2010-2014", value: "2010 - 2014" },
    { key: "2000-2009", value: "2000 - 2009" },
    { key: "1970-1999", value: "1970 - 1999" },
    { key: "1945-1969", value: "1945 - 1969" },
    { key: "1900-1944", value: "1900 - 1944" },
    { key: "1850-1899", value: "1850 - 1899" },
    { key: "1750-1849", value: "1750 - 1849" },
    { key: "1650-1749", value: "1650 - 1749" },
    { key: "1550-1649", value: "1550 - 1649" },
];

type Props = {
    onChange: (filter: FilterItemOption) => void;
};

export const FilterOptionConstructionYear = (props: Props) => {
    const { onChange } = props;

    const { translations } = useAssetFilterTranslations();

    const [value, setValue] = useState<string[]>([]);

    function onSelectedValue(event: SelectChangeEvent<typeof value>) {
        const selectedValues = event.target.value as string[];

        setValue(selectedValues);

        const filterValues = selectedValues.map((valueItem) => {
            const propertyItem = list.find(
                (option) => option.value === valueItem
            ) as FilterValue;

            return {
                ...propertyItem,
            };
        });

        onChange({
            category: AssetGroupFilterCategory.CONSTRUCTIONYEAR,
            values: filterValues,
        });
    }

    return (
        <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="asset-filter-construction-year-label">
                {translations[AssetGroupFilterCategory.CONSTRUCTIONYEAR]}
            </InputLabel>
            <Select
                labelId="asset-filter-construction-year-label"
                id="asset-filter-construction-year"
                multiple
                value={value}
                onChange={onSelectedValue}
                input={
                    <OutlinedInput
                        label={
                            translations[
                                AssetGroupFilterCategory.CONSTRUCTIONYEAR
                            ]
                        }
                    />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
            >
                {list.map((constructionYearItem) => (
                    <MenuItem
                        key={constructionYearItem.key}
                        value={constructionYearItem.value}
                    >
                        <Checkbox
                            checked={value.includes(constructionYearItem.value)}
                        />
                        <ListItemText primary={constructionYearItem.value} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
