import { Box, Button as MuiButton } from "@mui/material";
import { isValidElement, PropsWithChildren } from "react";

import { StyledButtonProps } from "./button.types";

function LeftIcon(props: PropsWithChildren) {
    const { children } = props;
    return <Box>{children}</Box>;
}

function RightIcon(props: PropsWithChildren) {
    const { children } = props;
    return <Box>{children}</Box>;
}

function Label(props: PropsWithChildren) {
    const { children } = props;
    return <Box>{children}</Box>;
}

export const Button = (props: StyledButtonProps) => {
    const { children, ...rest } = props;

    const listChildren = Array.isArray(children) ? children : [children];

    const leftIcon = listChildren.find((child) => {
        return isValidElement(child) && child.type === LeftIcon;
    });

    const rightIcon = listChildren.find((child) => {
        return isValidElement(child) && child.type === RightIcon;
    });

    const label = listChildren.find((child) => {
        return isValidElement(child) && child.type === Label;
    });

    return (
        <MuiButton {...rest}>
            {leftIcon}
            {label}
            {rightIcon}
        </MuiButton>
    );
};

Button.LeftIcon = LeftIcon;
Button.RightIcon = RightIcon;
Button.Label = Label;
