import { useMutation, useQuery } from "@apollo/client";
import {
    Box,
    Button,
    CircularProgress,
    List,
    ListItem,
    Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import {
    ActiveOrgIntegrationsQuery,
    CreateOrganizationIntegrationsMutation,
    CreateOrganizationIntegrationsMutationVariables,
    IntegrationType,
} from "graphql-types/graphql";
import { LoadingContainer } from "styling/box";
import request from "utils/request";

import {
    ACTIVE_INTEGRATIONS,
    CREATE_ORG_INTEGRATIONS,
} from "./integrationQueries";

const getIntegrationUrl = (integrationType: IntegrationType) => {
    const endpoint = process.env.REACT_APP_API_URL;

    switch (integrationType) {
        case IntegrationType.EPC:
            return `${endpoint}/integration-epc/own-organization`;
        case IntegrationType.EPC_UK:
            return `${endpoint}/epc-uk/own-organization`;
        case IntegrationType.EPC_DK:
            return `${endpoint}/epc-dk/own-organization`;
    }
};

const handleIntegrationClick = (integrationType: IntegrationType) => {
    const url = getIntegrationUrl(integrationType);
    return url ? request(url) : null;
};

function Integration(props: {
    integrationType: IntegrationType;
    createIntegration: (integrationType: IntegrationType) => Promise<any>;
}) {
    const { data } = useQuery<ActiveOrgIntegrationsQuery>(ACTIVE_INTEGRATIONS, {
        fetchPolicy: "cache-only",
    });

    const { integrationType, createIntegration } = props;

    const [loading, setLoading] = useState(false);

    const organization = useMemo(() => {
        return data?.me.organization;
    }, [data]);

    const isActive = useMemo(() => {
        return organization?.integrations.find(
            (i) => i.type === integrationType
        );
    }, [organization, integrationType]);

    const handleRunIntegration = useCallback(async () => {
        if (!organization) {
            return;
        }
        handleIntegrationClick(integrationType)
            ?.then(() => {
                alert(`Started ${integrationType} for ${organization.name}`);
            })
            .catch(() => {
                alert(`${integrationType} for ${organization.name} failed`);
            });
    }, [organization, integrationType]);

    const handleClick = useCallback(() => {
        if (isActive) {
            return handleRunIntegration();
        }
        setLoading(true);
        createIntegration(integrationType).then(() => {
            setLoading(false);
        });
    }, [isActive, handleRunIntegration, integrationType, createIntegration]);

    return (
        <Box display="flex" alignItems="center">
            <Box marginRight={4}>Integration: {integrationType}</Box>
            <Button
                variant="contained"
                color={isActive && "primary"}
                onClick={handleClick}
            >
                {isActive ? "Active - Run integration" : "Enable"}
                {loading && (
                    <LoadingContainer>
                        <CircularProgress color="secondary" />
                    </LoadingContainer>
                )}
            </Button>
        </Box>
    );
}

// An OIS organization integration can be created explicitly here.
// It can also be created implicitly in the location onboarding
const integrationTypes = [
    IntegrationType.EPC,
    IntegrationType.EPC_UK,
    IntegrationType.EPC_DK,
    IntegrationType.OIS,
];

function IntegrationSetup() {
    const { data, loading } =
        useQuery<ActiveOrgIntegrationsQuery>(ACTIVE_INTEGRATIONS);
    const [createIntegrations] = useMutation<
        CreateOrganizationIntegrationsMutation,
        CreateOrganizationIntegrationsMutationVariables
    >(CREATE_ORG_INTEGRATIONS);

    const organization = useMemo(() => {
        return data?.me.organization;
    }, [data]);

    const handleIntegrationSelect = useCallback(
        (integrationType: IntegrationType) => {
            if (!organization) {
                return Promise.resolve();
            }
            return createIntegrations({
                variables: {
                    createManyOrganizationIntegrations: {
                        organizationIntegrations: [{ type: integrationType }],
                    },
                },
                refetchQueries: [ACTIVE_INTEGRATIONS],
            });
        },
        [organization, createIntegrations]
    );

    if (loading) {
        return null;
    }

    return (
        <Box marginTop={4} marginBottom={4} bgcolor="white" padding={4}>
            <Typography variant="h3">Available Integrations</Typography>
            <List>
                {integrationTypes.map((type) => (
                    <ListItem key={type}>
                        <Integration
                            integrationType={type}
                            createIntegration={handleIntegrationSelect}
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default IntegrationSetup;
