import {
    GridToolbarExportProps,
    GridToolbarExport as MUIGridToolbarExport,
} from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const TableToolbarExport = (
    props: GridToolbarExportProps & { hasCustomStyle?: boolean }
) => {
    const { t } = useTranslation();
    const { csvOptions, hasCustomStyle, ...rest } = props;

    const csvOptionsWithDelimiter = useMemo(
        () => ({
            delimiter: t("common.csvDelimiter", ","),
            ...csvOptions,
        }),
        [t, csvOptions]
    );

    return (
        <MUIGridToolbarExport
            {...rest}
            slotProps={{ button: { variant: "outlined" } }}
            csvOptions={csvOptionsWithDelimiter}
            printOptions={{ disableToolbarButton: true }}
        />
    );
};
