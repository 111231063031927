import { useLazyQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { GridEventListener, useGridApiRef } from "@mui/x-data-grid";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { DYNAMIC_GRID_TABLE_HEIGHT } from "components/Table";
import { AssetContext } from "containers/AssetPage/AssetPage";
import {
    GetAllAssetAutomaticSourcesQuery,
    GetAllAssetAutomaticSourcesQueryVariables,
} from "graphql-types/graphql";
import { downloadCSV } from "utils/file.utils";

import { useAssetIntegrationsQuery } from "./automatic-source-table.hooks";
import { S } from "./automatic-source-table.styles";
import {
    formatAssetIntegrationsToAutomaticSources,
    getAllocationColumns,
    getColumns,
} from "./automatic-sources-table.helpers";
import { GET_ALL_ASSET_AUTOMATIC_SOURCES } from "./automaticSourcesTable.query";
import { AutomaticSourceRowData } from "./automaticSourcesTable.types";
import AutomaticSourceToolbar from "./components/AutomaticSourceToolbar";
import { DataSourceDrawer } from "./components/DataSourceDrawer";

type Props = {
    assetId: string;
};

export const AutomaticSourcesTable = (props: Props) => {
    const { assetId } = props;
    const { t } = useTranslation(["translation", "report"]);

    const asset = useContext(AssetContext);
    const apiRef = useGridApiRef();

    const { useConsumptionAllocation } = useFlags();

    const [getAllAssetSources] = useLazyQuery<
        GetAllAssetAutomaticSourcesQuery,
        GetAllAssetAutomaticSourcesQueryVariables
    >(GET_ALL_ASSET_AUTOMATIC_SOURCES, {
        variables: {
            locationId: assetId,
        },
    });

    const { totalCount, sources, loading, tablePaginationProps, filterProps } =
        useAssetIntegrationsQuery(assetId);

    // State
    const [drawerState, setDrawerState] = useState<{
        isOpen: boolean;
        data?: AutomaticSourceRowData;
    }>({ isOpen: false });

    const onRowClick: GridEventListener<"rowClick"> = (params) => {
        setDrawerState({
            data: { assetName: asset.displayName, ...params.row },
            isOpen: true,
        });
    };

    const columns = useMemo(() => {
        const defaultColumns = getColumns(t);

        if (!useConsumptionAllocation) return defaultColumns;

        return [...defaultColumns, ...getAllocationColumns(t)];
    }, [t, useConsumptionAllocation]);

    const sourcesRows = formatAssetIntegrationsToAutomaticSources(
        sources,
        asset.longAddress
    );

    const handleExport = useCallback(() => {
        const fileName = `${asset.displayName} - ${t(
            "automaticSources.fileName",
            "automatic_sources"
        )}`;
        toast.info(`${t("common.download", "Download")}...`);

        getAllAssetSources()
            .then((res) => {
                const data = res.data?.location.integrations ?? [];
                const rows = formatAssetIntegrationsToAutomaticSources(
                    data,
                    asset.longAddress
                );

                const header = columns.map((column) => column.headerName);
                const csvRows = rows.map((row) =>
                    columns.map((column) => {
                        const value = row[column.field as keyof typeof row];
                        const formatter = column.valueFormatter as any;

                        return formatter ? formatter(value) : value ?? "-";
                    })
                );

                const csv = [header, ...csvRows];

                downloadCSV(csv, `${fileName}.csv`);
            })
            .catch(() => {
                toast.error(
                    t(
                        "common.toast.error",
                        "Something went wrong, please try again"
                    )
                );
            });
    }, [asset.displayName, asset.longAddress, t, getAllAssetSources, columns]);

    return (
        <Box>
            <AutomaticSourceToolbar
                count={totalCount}
                onDownload={handleExport}
                {...filterProps}
            />

            <DataSourceDrawer
                data={drawerState.data}
                isOpen={drawerState.isOpen}
                onClose={() =>
                    setDrawerState((prevState) => ({
                        ...prevState,
                        isOpen: false,
                    }))
                }
            />
            <S.Table
                columns={columns}
                rows={sourcesRows}
                onRowClick={useConsumptionAllocation ? onRowClick : undefined}
                setCustomHeight={DYNAMIC_GRID_TABLE_HEIGHT}
                minHeight="400px"
                apiRef={apiRef}
                isLoading={loading}
                /* Pagination fields */
                paginationMode="server"
                sortingMode="server"
                {...tablePaginationProps}
            />
        </Box>
    );
};
