import { gql } from "@apollo/client";

export const PROPERTY_SIZE_QUERY = gql`
    query GetAssetPropertySize {
        me {
            id
            organization {
                buildingAreas {
                    floorAreaType
                    area {
                        min
                        max
                    }
                }
            }
        }
    }
`;
