import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AllocationObjectType } from "graphql-types/graphql";

import {
    getAllocationErrors,
    getAllocationErrorText,
} from "./property-allocation.helpers";
import { PropertyAllocationEditItem } from "./property-allocation.types";
import { PropertyAllocationContainer } from "./PropertyAllocationContainer";
import { usePropertyAllocationQuery } from "./usePropertyAllocationQuery";
import { DrawerField } from "../DrawerField";
import { EditableFieldList } from "../EditableFieldList";

type Props = {
    id: string;
    assetName: string;
    assetId: string;
};

export const PropertyAllocationField = (props: Props) => {
    const { id, assetName, assetId } = props;

    const { t } = useTranslation();

    const { data, loading, updateAllocation } = usePropertyAllocationQuery(
        id,
        assetName,
        assetId
    );
    const [items, setItems] = useState<PropertyAllocationEditItem[]>([]);

    useEffect(() => {
        if (loading) {
            return;
        }

        setItems(
            data.map((item) => ({
                key: item.id,
                label: item.name,
                value: item.allocation ?? 0,
                objectType: item.objectType,
                isChecked: Boolean(item.allocation),
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const handleEditItem = (key: string, value: number) => {
        setItems((prev) =>
            prev.map((item) => (item.key === key ? { ...item, value } : item))
        );
    };

    const handleCheckItem = (key: string) => {
        setItems((prev) =>
            prev.map((item) =>
                item.key === key
                    ? {
                          ...item,
                          value: 0,
                          isChecked: !item.isChecked,
                      }
                    : item
            )
        );
    };

    const handleSave = async () => {
        const allocations = items
            .filter((item) => item.isChecked)
            .map((item) => ({
                percentage: item.value,
                propertyId:
                    item.objectType !== AllocationObjectType.ASSET
                        ? item.key
                        : undefined,
                assetId:
                    item.objectType === AllocationObjectType.ASSET
                        ? item.key
                        : undefined,
            }));

        await updateAllocation({
            variables: {
                integrationId: id,
                allocations,
            },
        });
    };

    const handleCancel = () => {
        setItems(
            data.map((item) => ({
                key: item.id,
                label: item.name,
                value: item.allocation ?? 0,
                objectType: item.objectType,
                isChecked: Boolean(item.allocation),
            }))
        );
    };

    const allocationErrors = getAllocationErrors(items);

    return (
        <DrawerField
            tooltip={t(
                "asset.sourceDrawer.propertyAllocationTooltip",
                "Changes to property allocation will trigger a recalculation of emissions data."
            )}
            label={t("common.labels.propertyAllocation", "Property Allocation")}
            isSaveEnabled={allocationErrors.length === 0}
            onSave={handleSave}
            onCancel={handleCancel}
        >
            <PropertyAllocationContainer data={data}>
                {allocationErrors.map((error) => (
                    <Alert severity="error" sx={{ py: 1, width: "100%" }}>
                        {getAllocationErrorText(error)}
                    </Alert>
                ))}
                <EditableFieldList
                    errors={allocationErrors}
                    items={items}
                    onCheckItem={handleCheckItem}
                    onEditItem={handleEditItem}
                />
            </PropertyAllocationContainer>
        </DrawerField>
    );
};
