import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Logo from "assets/legacyLogoPaleBlueIconWithName.png";
import auth from "utils/auth";
import { updateFlagsWithContext } from "utils/featureFlags";
import request from "utils/request";

import * as S from "./AuthPageMuiStyle";

type AuthInput = {
    identifier: string;
    password: string;
    rememberMe: string;
};

const AuthPage = () => {
    const { t } = useTranslation();
    const ldClient = useLDClient();
    const { register, handleSubmit } = useForm<AuthInput>({});
    const navigate = useNavigate();

    const [errorPopUp, setErrorPopUp] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const [showPassword, setShowPassword] = useState("password");

    const showHidePass = () => {
        setPasswordVisibility(!passwordVisibility);
        passwordVisibility ? setShowPassword("") : setShowPassword("password");
    };

    const redirectUser = () => {
        navigate("/");
    };

    const getRequestOptions = (accessBody: any) => {
        const baseOpts = {
            method: "POST",
        };

        return {
            ...baseOpts,
            body: {
                password: accessBody.password,
                email: accessBody.identifier,
            },
        };
    };

    const onLogin = (body: AuthInput) => {
        const endPointUrl = auth.getEndpointUrl();
        const requestURL = `${endPointUrl}/auth/login`;
        request(requestURL, getRequestOptions(body))
            .then((response) => {
                const { user, access_token } = response;
                auth.setToken(access_token, body.rememberMe);
                auth.setUserInfo(user, body.rememberMe);
                updateFlagsWithContext(
                    ldClient,
                    user.organizationId,
                    user.organizationName,
                    user.id,
                    user.name
                );
                redirectUser();
            })
            .catch(() => {
                setErrorPopUp(true);
            });
    };

    const { useResetPassword } = useFlags();

    return (
        <form onSubmit={handleSubmit(onLogin)}>
            <S.FullSizeContainer>
                <S.BackContainer>
                    <S.ImageContainer>
                        <S.ImageBox variant="square" src={Logo} alt="logo" />
                    </S.ImageContainer>

                    <S.LoginContainer>
                        <S.TextTypo>Email</S.TextTypo>
                        <S.InputText
                            {...register("identifier")}
                            name="identifier"
                            fullWidth
                            required
                            InputProps={{
                                inputProps: {
                                    style: { textAlign: "left" },
                                },
                            }}
                        />
                        <S.TextTypo>
                            {t("auth.password", "Password")}
                            <S.PasswordVisibilityBox onClick={showHidePass} />
                        </S.TextTypo>
                        <S.InputText
                            {...register("password")}
                            name="password"
                            type={showPassword}
                            fullWidth
                            required
                            InputProps={{
                                inputProps: {
                                    style: { textAlign: "left" },
                                },
                            }}
                        />
                        {useResetPassword && (
                            <Link
                                to="/auth/resetpassword"
                                style={{ textAlign: "right" }}
                            >
                                {t("auth.forgotten", "Forgot your password?")}
                            </Link>
                        )}
                        <S.LoginButton
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            {t("auth.login", "Login")}
                        </S.LoginButton>
                    </S.LoginContainer>
                    {errorPopUp && (
                        <S.ForgotPasswordBox
                            onClickCapture={() => {
                                setErrorPopUp(false);
                            }}
                        >
                            <S.WrongCredentialsHeadline>
                                {t(
                                    "auth.errorSigningInTitle",
                                    "Oh snap! You got an error."
                                )}
                            </S.WrongCredentialsHeadline>
                            <S.WrongCredentialsText>
                                {t(
                                    "auth.errorSigningIn",
                                    "Your username or password is incorrect."
                                )}
                            </S.WrongCredentialsText>
                        </S.ForgotPasswordBox>
                    )}
                </S.BackContainer>
            </S.FullSizeContainer>
        </form>
    );
};

export default AuthPage;
