import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { match } from "ts-pattern";

import {
    GetEloverblikCustomersQuery,
    IntegrationType,
} from "graphql-types/graphql";

import { GET_ELOVERBLIK_CUSTOMERS } from "./queries";

export const useIntegrationCustomers = (type: IntegrationType) => {
    const [fetchData, setFetchData] = useState<
        { value: string; label: string }[]
    >([]);

    const eloverblikCustomerQuery = useLazyQuery<GetEloverblikCustomersQuery>(
        GET_ELOVERBLIK_CUSTOMERS,
        {
            onCompleted: (data) => {
                const customers = data.getEloverblikCustomers
                    .map((customer) => ({
                        value: customer.customerCVR,
                        label: `${customer.customerName} (${customer.customerCVR})`,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label));

                setFetchData(customers);
            },
        }
    );

    const fetchQuery = match(type)
        .with(IntegrationType.ELOVERBLIK, () => eloverblikCustomerQuery)
        .otherwise(() => null);

    return { fetchData, fetchQuery };
};
