import { Box } from "@mui/material";
import { useState } from "react";

import { FilterItemOption, FilterItemOptions } from "utils/asset-group-filters";

import { FilterOptionConstructionYear } from "./FilterOptionConstructionYear";
import { FilterOptionCrremPropertyType } from "./FilterOptionCRREM";
import { FilterOptionPropertySize } from "./FilterPropertySize/FilterPropertySize";

export const Filters = () => {
    const [filters, setFilters] = useState<FilterItemOptions>([]);

    function onFilterChange(filter: FilterItemOption) {
        const hasFilter = filters.some((currentFilter) => {
            return currentFilter.category === filter.category;
        });

        if (!hasFilter) {
            setFilters([...filters, filter]);
            return;
        }

        const pendingFilters = filters.map((currentFilter) => {
            if (currentFilter.category === filter.category) {
                const values = filter.values;
                return {
                    ...currentFilter,
                    values,
                };
            }
            return currentFilter;
        });

        setFilters(pendingFilters);
    }

    return (
        <Box>
            <FilterOptionCrremPropertyType onChange={onFilterChange} />
            <FilterOptionPropertySize onChange={onFilterChange} />
            <FilterOptionConstructionYear onChange={onFilterChange} />
        </Box>
    );
};
