import { useQuery, useMutation, ApolloError } from "@apollo/client";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
    UPDATE_LOCATION_INTEGRATION,
    DELETE_LOCATION_INTEGRATION_BY_IDS,
    ADD_LOCATION_INTEGRATION_MUTATION,
} from "containers/IntegrationOnboarding/graphql/locationIntegrationOnboardingMutation";
import { EditMappingState } from "containers/IntegrationOnboardingV2/types";
import {
    IntegrationType,
    GetLocationIntegrationsQuery,
    GetLocationIntegrationsQueryVariables,
    UpdateLocationIntegrationMutation,
    UpdateLocationIntegrationMutationVariables,
    DeleteLocationIntegrationByIdsMutation,
    DeleteLocationIntegrationByIdsMutationVariables,
    AddLocationIntegrationMutationVariables,
    AddLocationIntegrationMutation,
    CreateAssetIntegrationInput,
} from "graphql-types/graphql";

import { GET_LOCATION_INTEGRATION } from "./queries";

export const useAssetIntegrationMapping = (type: IntegrationType) => {
    const { t } = useTranslation();

    const { data, loading } = useQuery<
        GetLocationIntegrationsQuery,
        GetLocationIntegrationsQueryVariables
    >(GET_LOCATION_INTEGRATION, {
        variables: { type },
    });

    const [addLocationIntegration] = useMutation<
        AddLocationIntegrationMutation,
        AddLocationIntegrationMutationVariables
    >(ADD_LOCATION_INTEGRATION_MUTATION);

    const [updateLocationIntegrations] = useMutation<
        UpdateLocationIntegrationMutation,
        UpdateLocationIntegrationMutationVariables
    >(UPDATE_LOCATION_INTEGRATION);

    const [deleteLocationIntegrations] = useMutation<
        DeleteLocationIntegrationByIdsMutation,
        DeleteLocationIntegrationByIdsMutationVariables
    >(DELETE_LOCATION_INTEGRATION_BY_IDS, {
        refetchQueries: [GET_LOCATION_INTEGRATION],
    });

    const handleAdd = useCallback(
        (
            input: CreateAssetIntegrationInput[],
            locationCount: number,
            integrationsCount: number
        ) => {
            toast.promise<any, ApolloError>(
                addLocationIntegration({ variables: { input } }),
                {
                    pending: {
                        render: () =>
                            integrationsCount > 5
                                ? t(
                                      "integrationOnboarding.addingIntegrations",
                                      "Adding {{integrationsCount}} integrations for {{locationCount}} locations",
                                      { integrationsCount, locationCount }
                                  )
                                : null,
                    },
                    success: {
                        render: () =>
                            t(
                                "integrationOnboarding.createSuccess",
                                "Successfully added {{integrationsCount}} integrations for {{locationCount}} locations",
                                { integrationsCount, locationCount }
                            ),
                    },
                    error: {
                        render: ({ data }) =>
                            t(
                                "integrationOnboarding.createError",
                                "Failed to add integrations \n {{error}} ",
                                { error: data && data.message }
                            ),
                    },
                }
            );
        },
        [addLocationIntegration, t]
    );

    const handleUpdate = useCallback(
        (state: EditMappingState) => {
            const updates = Object.values(state).map(
                ({ integrations, period }) => ({
                    startedAt: period.from?.toISO(),
                    endedAt: period.to?.toISO(),
                    ids: integrations.map((i) => i.value),
                })
            );

            if (!updates.length) return;

            toast.promise(
                Promise.all(
                    updates.map((update) =>
                        updateLocationIntegrations({
                            variables: {
                                startedAt: update.startedAt,
                                endedAt: update.endedAt,
                                ids: update.ids,
                            },
                        })
                    )
                ),
                {
                    pending: t("labels.updating", "Updating..."),
                    success: t("labels.updated", "Updated"),
                    error: t("labels.error", "Error"),
                }
            );
        },
        [updateLocationIntegrations, t]
    );

    const handleDelete = useCallback(
        (state: EditMappingState) => {
            const deletionIds = Object.values(state).flatMap(
                ({ integrations }) => integrations.map((i) => i.value)
            );

            toast.promise(
                deleteLocationIntegrations({
                    variables: {
                        ids: deletionIds,
                    },
                }),
                {
                    pending: t("labels.deleting", "Deleting..."),
                    success: t("labels.deleted", "Deleted"),
                    error: t("labels.error", "Error"),
                }
            );
        },
        [deleteLocationIntegrations, t]
    );

    return {
        data,
        loading,
        handleAdd,
        handleUpdate,
        handleDelete,
    };
};
