import { useApolloClient, useMutation } from "@apollo/client";
import _ from "lodash";

import {
    AssetIntegrationInput,
    CreateLocationInput,
    CreateManyIntegrationsMutation,
    CreateManyIntegrationsMutationVariables,
    CreateSingleLocationMutation,
    CreateSingleLocationMutationVariables,
    GetOrgDataForOnboardingQuery,
    UpdateSingleLocationMutation,
    UpdateSingleLocationMutationVariables,
} from "graphql-types/graphql";

import {
    CREATE_MANY_INTEGRATIONS,
    CREATE_SINGLE_LOCATION,
    UPDATE_SINGLE_LOCATION,
} from "./importQueries";
import { Awaited } from "../../utils/appTypes";

type OrganizationIntegrationOnboardingAsset = NonNullable<
    NonNullable<
        GetOrgDataForOnboardingQuery["me"]["organization"]
    >["locations"]["edges"]
>[0]["node"];

const compareIntegrations = (
    newInt: AssetIntegrationInput,
    existing: OrganizationIntegrationOnboardingAsset["integrations"][0]
) => {
    const isSameType = existing.type === newInt.type;
    if (isSameType && existing.data && newInt.data) {
        if ("municipality" in existing.data && "municipality" in newInt.data) {
            return (
                existing.data.municipality === newInt.data.municipality &&
                existing.data.property === newInt.data.property &&
                existing.data.building === newInt.data.building
            );
        }
        if ("buildingType" in existing.data && "buildingType" in newInt.data) {
            return (
                existing.data.buildingType === newInt.data.buildingType &&
                existing.data.address1 === newInt.data.address1 &&
                existing.data.address2 === newInt.data.address2 &&
                existing.data.address3 === newInt.data.address3 &&
                existing.data.postcode === newInt.data.postcode
            );
        }
        if ("type" in existing.data && "type" in newInt.data) {
            return (
                existing.data.type === newInt.data.type &&
                existing.data.dexmaId === newInt.data.id
            );
        }
        if ("directoryId" in existing.data && "directoryId" in newInt.data) {
            return existing.data.directoryId === newInt.data.directoryId;
        }
        if ("propertyId" in existing.data && "propertyId" in newInt.data) {
            return existing.data.propertyId === newInt.data.propertyId;
        }
    }
    return false;
};

const useCreateOrUpdateIntegrations = () => {
    const [createIntegrations] = useMutation<
        CreateManyIntegrationsMutation,
        CreateManyIntegrationsMutationVariables
    >(CREATE_MANY_INTEGRATIONS); // Anchor: We don't update integrations only create them

    const handleUpload = async (
        assetId: string,
        integrations: CreateLocationInput["integrations"],
        existingIntegrations?: OrganizationIntegrationOnboardingAsset["integrations"]
    ) => {
        if (!integrations) {
            return null;
        }

        const newIntegrations = !existingIntegrations
            ? integrations
            : _.differenceWith(
                  integrations,
                  existingIntegrations,
                  compareIntegrations
              );

        if (newIntegrations.length) {
            const createdIntegrations = await createIntegrations({
                variables: {
                    locationIntegrations: newIntegrations.map((i) => ({
                        ...i,
                        data: i.data ? JSON.stringify(i.data) : undefined,
                        assetId,
                    })),
                },
            });

            return createdIntegrations;
        }

        return null;
    };
    return [handleUpload];
};

const useCreateOrUpdateLocation = () => {
    const [createLocation] = useMutation<
        CreateSingleLocationMutation,
        CreateSingleLocationMutationVariables
    >(CREATE_SINGLE_LOCATION);

    const [updateLocation] = useMutation<
        UpdateSingleLocationMutation,
        UpdateSingleLocationMutationVariables
    >(UPDATE_SINGLE_LOCATION);

    const [createOrUpdateIntegrations] = useCreateOrUpdateIntegrations();

    const handleUpload = async (
        { integrations, ...locationCreator }: CreateLocationInput,
        currentLocation?: OrganizationIntegrationOnboardingAsset
    ) => {
        if (currentLocation) {
            const createdIntegrations = integrations
                ? await createOrUpdateIntegrations(
                      currentLocation.id,
                      integrations,
                      currentLocation.integrations
                  )
                : null;

            const updatedLocation = locationCreator.bfeCodes?.length
                ? await updateLocation({
                      variables: {
                          input: {
                              id: currentLocation.id,
                              update: {
                                  bfeCodes: locationCreator.bfeCodes,
                              },
                          },
                      },
                  })
                : null;

            return { currentLocation, updatedLocation, createdIntegrations };
        } else if (!currentLocation) {
            const createdLocationData = await createLocation({
                variables: {
                    location: { ...locationCreator, integrations },
                },
            });

            const createdLocation = createdLocationData.data?.createOneLocation;

            return { createdLocation };
        }

        return null;
    };
    return [handleUpload];
};

export const useCreateLocationsAndIntegrations = () => {
    const [createOrUpdateLocation] = useCreateOrUpdateLocation();
    const apolloClient = useApolloClient();

    const handleUpload = async (
        newLocations: CreateLocationInput[],
        existingLocations: OrganizationIntegrationOnboardingAsset[]
    ) => {
        const promises: Promise<
            Awaited<ReturnType<typeof createOrUpdateLocation>>
        >[] = [];
        for (const newLocation of newLocations) {
            const existingLocation = existingLocations.find((existing) => {
                if (newLocation.externalId) {
                    return existing.externalId === newLocation.externalId;
                } else if (newLocation.name) {
                    return existing.name === newLocation.name;
                } else if (newLocation.internalDescriptor) {
                    return (
                        existing.internalDescriptor ===
                        newLocation.internalDescriptor
                    );
                }

                return false;
            });

            const data = createOrUpdateLocation(newLocation, existingLocation);

            promises.push(data);
        }
        return Promise.all(promises).then(() => {
            return apolloClient.refetchQueries({
                include: "active",
            });
        });
    };

    return [handleUpload];
};
