import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { ConfirmationMessage } from "./ConfirmationMessage";
import { EditMappingState } from "../../../types";

type Props = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    operation: "update" | "delete";
    selectedLocations: EditMappingState;
};

export const ConfirmationModal = (props: Props) => {
    const { open, onClose, onConfirm, operation, selectedLocations } = props;
    const { t } = useTranslation();

    const title =
        operation === "update"
            ? t("labels.update", "Update")
            : t("labels.delete", "Delete");

    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle variant="h3">{title}</DialogTitle>
            <DialogContent>
                <ConfirmationMessage
                    assets={selectedLocations}
                    operation={operation}
                />
            </DialogContent>
            <DialogActions sx={{ bgcolor: "grey.100" }}>
                <Button onClick={onClose} color="error">
                    {t("labels.cancel", "Cancel")}
                </Button>
                <Button onClick={handleConfirm} variant="outlined" autoFocus>
                    {t("labels.confirm", "Confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
