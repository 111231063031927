import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";

import { AssetGroupFilterCategory, CrremCategory } from "graphql-types/graphql";
import { useAssetFilterTranslations } from "hooks";
import { FilterItemOption, FilterValue } from "utils/asset-group-filters";

import { MenuProps } from "./filters.styles";

type Props = {
    onChange: (filter: FilterItemOption) => void;
};

export function FilterOptionCrremPropertyType(props: Props) {
    const { onChange } = props;

    const { translations } = useAssetFilterTranslations();

    const [value, setValue] = useState<string[]>([]);

    const propertyTypeList = Object.keys(CrremCategory).map(
        (propertyTypeKey) => ({
            key: propertyTypeKey,
            value: translations[propertyTypeKey as CrremCategory],
        })
    );

    function onSelectedValue(event: SelectChangeEvent<typeof value>) {
        const selectedValues = event.target.value as string[];

        setValue(selectedValues);

        const filterValues = selectedValues.map((valueItem) => {
            const propertyItem = propertyTypeList.find(
                (option) => option.value === valueItem
            ) as FilterValue;

            return {
                ...propertyItem,
            };
        });

        onChange({
            category: AssetGroupFilterCategory.CRREMPROPERTYTYPES,
            values: filterValues,
        });
    }

    return (
        <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="asset-filter-crrem-label">
                {translations[AssetGroupFilterCategory.CRREMPROPERTYTYPES]}
            </InputLabel>
            <Select
                labelId="asset-filter-crrem-label"
                id="asset-filter-crrem"
                multiple
                value={value}
                onChange={onSelectedValue}
                input={
                    <OutlinedInput
                        label={
                            translations[
                                AssetGroupFilterCategory.CRREMPROPERTYTYPES
                            ]
                        }
                    />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
            >
                {propertyTypeList.map((propertyType) => (
                    <MenuItem key={propertyType.key} value={propertyType.value}>
                        <Checkbox
                            checked={value.includes(propertyType.value)}
                        />
                        <ListItemText primary={propertyType.value} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
