import { Avatar, Box, Button, styled } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import companyLogo from "assets/legacyLogoIconLightBlue.png";
import { AssetGroupSelector } from "components/AssetsGroup";

import HeaderBar from "./HeaderBar";

const NavBar = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,

    overflow: "hidden",
    minWidth: "100%",
    height: theme.spacing(20),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    top: 0,
    left: 0,
    position: "fixed",
    zIndex: 10,

    padding: theme.spacing(2, 12.5),

    [theme.breakpoints.down("lg")]: {
        height: theme.spacing(34),
        alignItems: "flex-start",
        flexDirection: "column",
        paddingTop: theme.spacing(4),
    },
}));

const CompanyLogo = styled(Avatar)(({ theme }) => ({
    opacity: 0.95,
    transition: "all 0.1s ease-out",
    "&:hover": {
        opacity: 1,
        width: theme.spacing(11),
        height: theme.spacing(11),
    },
}));

const NavMenuItemsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",

    "& > *": {
        padding: "0 1.75rem",

        "&:hover": {
            color: theme.palette.common.black,
            textDecoration: "none",
        },

        "&.active": {
            color: theme.palette.text.primary,
            fontWeight: "bold",
        },
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 4),
        },
    },

    [theme.breakpoints.down("lg")]: {
        width: "100%",
        justifyContent: "left",
    },
}));

const ContentContainerBox = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(12),
}));

const NavBarLayout = (props: PropsWithChildren) => {
    const { t } = useTranslation();

    const { useStrategyPage } = useFlags();

    const navLinksRoutes = useMemo(
        () => [
            {
                label: t("common.portfolio", "Portfolio"),
                route: "/portfolio",
            },
            {
                label: t("navbar.assetlist", "Assets"),
                route: "/assetlist",
            },
            {
                label: t("navbar.reports", "Reports"),
                route: "/reports",
            },
            { label: t("navbar.data", "Data"), route: "/data-overview" },
            useStrategyPage
                ? {
                      label: "Strategy",
                      route: "/strategy",
                  }
                : {
                      label: t("navbar.forecast", "Forecasting"),
                      route: "/forecast",
                  },
            {
                label: t("navbar.whatsNew", "What's New"),
                className: "beamerTrigger",
            },
        ],
        [t, useStrategyPage]
    );

    return (
        <>
            <NavBar>
                <Box display="flex" alignItems="center" gap={4}>
                    <NavLink to="/">
                        <CompanyLogo variant="square" src={companyLogo} />
                    </NavLink>
                    <AssetGroupSelector />
                </Box>

                <NavMenuItemsContainer>
                    {navLinksRoutes.map((nav, i) => (
                        <Button
                            key={i}
                            to={nav.route ?? "#"}
                            className={nav.className}
                            disableRipple
                            sx={{
                                paddingLeft: 4,
                                paddingRight: 4,
                                "&:hover": { backgroundColor: "transparent" },
                                "&.active": {
                                    fontWeight: nav.route ? "bold" : "normal",
                                },
                            }}
                            component={NavLink}
                        >
                            {nav.label}
                        </Button>
                    ))}
                    <HeaderBar />
                </NavMenuItemsContainer>
            </NavBar>
            <ContentContainerBox>{props.children}</ContentContainerBox>
        </>
    );
};

export default NavBarLayout;
