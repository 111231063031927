import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
    getDataQualityTitle,
    mapDataQualitySources,
} from "components/DataQuality/DataQuality.helpers";
import { getAssessmentCategory } from "containers/DataUploadPage/utilities";
import { ConsumptionType, DataQualityTypes } from "graphql-types/graphql";
import { DASH_SEPARATOR, parseJSON } from "utils";
import {
    assessmentVerticalToTitle,
    getAssessmentCategoryTranslation,
    mapConsumptionTypeToVertical,
} from "utils/assessment";
import { validateAndFormatDateString } from "utils/date.utils";

import { S } from "./dataSourceDrawer.styles";
import { AllocationNoteField } from "./DrawerFields";
import { AutomaticSourceRowData } from "../automaticSourcesTable.types";

type Props = {
    data: AutomaticSourceRowData;
};

export const SourceDetailsPanel = (props: Props) => {
    const { data } = props;
    const { t } = useTranslation();

    const verticals = Array.isArray(data.consumptionType)
        ? data.consumptionType.map(mapConsumptionTypeToVertical)
        : [data.vertical];
    const categories = Array.isArray(data.consumptionType)
        ? data.consumptionType.map(getAssessmentCategory)
        : [getAssessmentCategory(data.consumptionType as ConsumptionType)];

    const address =
        (parseJSON(data.address) as string[])?.join(", ") ?? data.address;

    const details = [
        {
            label: t("common.labels.type", "Type"),
            data: data.type,
        },
        {
            label: t("common.labels.source", "Source"),
            data: mapDataQualitySources(
                data.source.source ?? DASH_SEPARATOR,
                t
            ),
        },
        {
            label: t("common.labels.dataQuality", "Data Quality"),
            data: data.dataQuality
                ? getDataQualityTitle(
                      DataQualityTypes[data.dataQuality],
                      false,
                      t
                  )
                : "-",
        },
        {
            label: t("common.labels.address", "Address"),
            data: address,
        },
        {
            label: t("common.labels.identifier", "Identifier"),
            data: data.identifier,
        },
        {
            label: t("common.labels.vertical", "Vertical"),
            data: verticals
                .map((vertical) => assessmentVerticalToTitle(vertical, t))
                .join(", "),
        },
        {
            label: t("common.labels.category", "Category"),
            data: categories
                .map((category) =>
                    getAssessmentCategoryTranslation(category, t)
                )
                .join(", "),
        },
        {
            label: t("common.labels.from", "From"),
            data: validateAndFormatDateString(data.from),
        },
        {
            label: t("common.labels.to", "To"),
            data: validateAndFormatDateString(data.to),
        },
    ];

    return (
        <S.Panel>
            {details.map(({ label, data }) => (
                <S.Container key={label}>
                    <Typography fontWeight="bold">{label}</Typography>
                    <Typography textAlign="right">
                        {data || DASH_SEPARATOR}
                    </Typography>
                </S.Container>
            ))}
            <AllocationNoteField
                id={data.id}
                note={data.allocationNote ?? ""}
            />
        </S.Panel>
    );
};
