import { Box, capitalize } from "@mui/material";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Drawer, Tab, Tabs, Tag } from "glue";
import { parseJSON } from "utils";

import { S } from "./dataSourceDrawer.styles";
import { PropertyAllocationField } from "./DrawerFields";
import { SourceDetailsPanel } from "./SourceDetailsPanel";
import { getStatusColor } from "../automatic-sources-table.helpers";
import { AutomaticSourceRowData } from "../automaticSourcesTable.types";

const getAddress = (dataAddress: any) => {
    const addresses = parseJSON(dataAddress) as string[];

    if (!addresses) return dataAddress;
    const [address, ...rest] = addresses;

    return `${address} ${rest.length > 0 ? ` [+${rest.length}]` : ""}`;
};

type Props = {
    data?: AutomaticSourceRowData;
    isOpen: boolean;
    onClose: () => void;
};

export const DataSourceDrawer = (props: PropsWithChildren<Props>) => {
    const { locationId } = useParams();
    const { t } = useTranslation();
    const { data, isOpen, onClose } = props;

    if (!data) {
        return;
    }

    const tagColor = getStatusColor(data.source.status);
    const address = getAddress(data.address);

    return (
        <Drawer anchor="right" width="30%" isOpen={isOpen} onClose={onClose}>
            <Drawer.Header>
                <Drawer.Title>{address}</Drawer.Title>
                <Drawer.Panel>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tag
                            color={tagColor}
                            label={capitalize(data.source.status)}
                        />
                        {data.identifier}
                    </Box>
                </Drawer.Panel>
            </Drawer.Header>
            <Drawer.Content>
                <Tabs
                    sx={{
                        mt: -3,
                        px: 8,
                        backgroundColor: "header.main",
                        borderBottomColor: "header.dark",
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                    }}
                >
                    <Tab label={t("asset.sourceDrawer.sourceDetails")}>
                        <SourceDetailsPanel data={data} />
                    </Tab>
                    <Tab
                        label={t(
                            "asset.sourceDrawer.dataAllocation",
                            "Data Allocation"
                        )}
                    >
                        <S.Panel>
                            <PropertyAllocationField
                                id={data.id}
                                assetName={data.assetName}
                                assetId={locationId ?? ""}
                            />
                        </S.Panel>
                    </Tab>
                </Tabs>
            </Drawer.Content>
        </Drawer>
    );
};
