import { FilterItemOption, FilterValue } from "components/AssetsGroup/types";
import { AssetGroupFilterCategory, CrremCategory } from "graphql-types/graphql";
import { useAssetFilterTranslations } from "hooks";

import { FilterOptionSelect } from "../OptionLayout";

type Props = {
    onChange: (filter: FilterItemOption) => void;
};

export function FilterOptionCrremPropertyType(props: Props) {
    const { onChange } = props;
    const { translations } = useAssetFilterTranslations();

    const propertyTypeList = Object.keys(CrremCategory).map(
        (propertyTypeKey) => ({
            key: propertyTypeKey,
            value: translations[propertyTypeKey as CrremCategory],
        })
    );

    function onSelectedValue(value: FilterValue) {
        onChange({
            category: AssetGroupFilterCategory.CRREMPROPERTYTYPES,
            values: [value],
        });
    }

    return (
        <FilterOptionSelect
            list={propertyTypeList}
            onSelectedValue={onSelectedValue}
        />
    );
}
