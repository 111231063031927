import { gql } from "@apollo/client";

export const GET_ORGANIZATION_INTEGRATIONS = gql`
    query GetOrganizationIntegrations {
        me {
            id
            organization {
                integrations {
                    id
                    type
                }
            }
        }
    }
`;

export const ADD_ORGANIZATION_INTEGRATION = gql`
    mutation CreateOrganizationIntegration(
        $type: IntegrationType!
        $data: JSON
    ) {
        createOneOrganizationIntegration(
            input: { organizationIntegration: { type: $type, data: $data } }
        ) {
            id
        }
    }
`;

export const UPDATE_ORGANIZATION_INTEGRATION = gql`
    mutation UpdateOrganizationIntegration(
        $input: UpdateOneOrganizationIntegrationInput!
    ) {
        updateOneOrganizationIntegration(input: $input) {
            id
        }
    }
`;

export const GET_ELOVERBLIK_CUSTOMERS = gql`
    query GetEloverblikCustomers {
        getEloverblikCustomers {
            customerCVR
            customerName
        }
    }
`;
