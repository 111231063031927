export enum SetPasswordFormState {
    LOADING,
    NOT_VALIDATED,
    VALIDATED,
    ERROR,
    SUCCESS,
}

export type SetPasswordForm = {
    password: string;
    confirmPassword: string;
};
