import { useState } from "react";

import {
    OrganizationIntegrationOnboarding,
    AssetIntegrationOnboarding,
} from "containers/IntegrationOnboardingV2";
import { IntegrationType } from "graphql-types/graphql";

export const SettingsIntegrationsTab = () => {
    const [activeTab, setActiveTab] = useState<IntegrationType | null>(null);

    if (!activeTab)
        return (
            <OrganizationIntegrationOnboarding setActiveTab={setActiveTab} />
        );

    return (
        <AssetIntegrationOnboarding
            integrationType={activeTab}
            setActiveTab={setActiveTab}
        />
    );
};
