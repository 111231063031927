import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Header = () => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="h2" sx={{ mb: 2 }}>
                {t("assessmentOverview.assetList.header.title", "Your Assets")}
            </Typography>
            <Typography variant="h5" color="grey.400">
                {t(
                    "assessmentOverview.assetList.header.subHeader",
                    "YTD emissions are calculated until the previous day."
                )}
            </Typography>
            <Typography variant="h5" color="grey.400">
                {t(
                    "assessmentOverview.assetList.header.subSubHeader",
                    "All emissions calculations will be based on available meter data, unless selected otherwise in the filter."
                )}
            </Typography>

            <Typography sx={{ my: 4, width: "100%", textAlign: "center" }}>
                Filters
            </Typography>
        </>
    );
};
