import { Divider, Grid, MenuItem, Select, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import BasicDatePicker from "components/DatePicker/DatePicker";
import { Button } from "glue/Button";
import {
    CountryCode,
    DkBuildingUse,
    GetPropertyQuery,
    PropertyObjectType,
    PropertyOwnershipType,
    UkPropertyType,
} from "graphql-types/graphql";
import { getDirtyValues } from "utils/forms.utils";
import {
    getNationalPropertyTranslation,
    getPropertyObjectTypeTranslation,
    getPropertyOwnershipTranslation,
} from "utils/translations";

import { S } from "./property-modal.styles";
import { PropertyFormField as FormField } from "./PropertyFormField";
import { getPropertyTypeOptions } from "../../components";
import { ModalProperty } from "../../types";

type Props = {
    onClose: () => void;
    onConfirmAddProperty?: (values: ModalProperty) => void;
    onConfirmEditProperty?: (values: Partial<ModalProperty>) => void;
    parentObjectType?: PropertyObjectType;
    countryCode: CountryCode;
    isEditMode: boolean;
    property: GetPropertyQuery["property"] | undefined;
};

export const PropertyForm = (props: Props) => {
    const {
        onConfirmAddProperty,
        onConfirmEditProperty,
        onClose,
        parentObjectType,
        countryCode,
        isEditMode,
        property,
    } = props;

    const { t } = useTranslation();

    const defaultValues: ModalProperty = useMemo(
        () => ({
            name: property?.name || "",
            identifier: property?.identifier || "",
            objectType: property?.objectType || "",
            ownershipType:
                property?.ownershipType || PropertyOwnershipType.MIXED,
            nationalPropertyType:
                (property?.nationalPropertyTypeValue as any) || "",
            constructionDate: property?.constructionDate
                ? DateTime.fromISO(property.constructionDate).toJSDate()
                : null,
            purchaseDate: property?.purchaseDate
                ? DateTime.fromISO(property.purchaseDate).toJSDate()
                : null,
            saleDate: property?.saleDate
                ? DateTime.fromISO(property.saleDate).toJSDate()
                : null,
            buildingArea: property?.buildingArea ?? undefined,
            address: {
                zip: property?.address?.zip || "",
                city: property?.address?.city || "",
                street: property?.address?.street || "",
                houseNumber: property?.address?.houseNumber || "",
            },
        }),
        [property]
    );

    const {
        control,
        reset,
        setValue,
        handleSubmit,
        watch,
        formState: { errors, dirtyFields },
    } = useForm<ModalProperty>({
        defaultValues,
    });

    const onAddOrEdit = (values: ModalProperty) => {
        if (isEditMode) {
            if (!onConfirmEditProperty) return;

            const updatedFields = getDirtyValues(dirtyFields, values);

            const isAddressUpdated = Object.keys(updatedFields).some((key) =>
                key.startsWith("address")
            );
            if (isAddressUpdated) {
                updatedFields.address = {
                    ...property?.address,
                    ...updatedFields.address,
                };
            }

            return onConfirmEditProperty(updatedFields as any);
        }

        if (!onConfirmAddProperty) return;
        return onConfirmAddProperty(values);
    };

    const handleCancel = () => {
        reset(defaultValues);
        onClose();
    };

    const ownershipOptions = Object.values(PropertyOwnershipType).map(
        (item) => (
            <MenuItem value={item} key={item} sx={{ whiteSpace: "normal" }}>
                {getPropertyOwnershipTranslation(item)}
            </MenuItem>
        )
    );

    const countryOptions = match(countryCode)
        .with(CountryCode.DK, () => Object.values(DkBuildingUse))
        .with(CountryCode.GB, () => Object.values(UkPropertyType))
        .otherwise(() => []);

    const nationalPropertyTypeOptions = countryOptions.map((item) => (
        <MenuItem value={item} key={item} sx={{ whiteSpace: "normal" }}>
            {getNationalPropertyTranslation(item, countryCode)}
        </MenuItem>
    ));

    const propertyTypeList = getPropertyTypeOptions(parentObjectType);
    const objectTypesOptions = propertyTypeList.map((item) => (
        <MenuItem value={item} key={item} sx={{ whiteSpace: "normal" }}>
            {getPropertyObjectTypeTranslation(item)}
        </MenuItem>
    ));

    const disableSave = Object.keys(errors).length > 0;

    const propertyTypeWatch = watch("objectType");

    const isConstructionDateDisabled =
        propertyTypeWatch !== PropertyObjectType.BUILDING;

    useEffect(() => {
        if (isConstructionDateDisabled) {
            setValue("constructionDate", null); // Clear the input field
        }
    }, [propertyTypeWatch, setValue, isConstructionDateDisabled]);

    return (
        <S.Form onSubmit={handleSubmit(onAddOrEdit)}>
            <S.Body container spacing={4}>
                <FormField
                    id="name"
                    title={`${t("assetDetailsComponent.name", "name")} *`}
                    control={control}
                    rules={{ required: true, minLength: 2 }}
                >
                    <TextField error={Boolean(errors.name)} />
                </FormField>
                <FormField
                    id="objectType"
                    title={`${t(
                        "assetDetailsComponent.propertyType",
                        "property type"
                    )} *`}
                    control={control}
                    rules={{ required: true }}
                    tooltip={t(
                        "assetDetailsComponent.updatePropertyTypeTooltip",
                        "Once set, the property type cannot be updated."
                    )}
                    iconType={isEditMode ? "info" : "warning"}
                >
                    <Select
                        disabled={isEditMode}
                        error={Boolean(errors.objectType)}
                        labelId="objectType-label"
                    >
                        {objectTypesOptions}
                    </Select>
                </FormField>
                <FormField
                    id="ownershipType"
                    title={`${t(
                        "assetDetailsComponent.ownershipType",
                        "ownership type"
                    )} *`}
                    control={control}
                    rules={{ required: true }}
                >
                    <Select
                        error={Boolean(errors.ownershipType)}
                        labelId="ownershipType-label"
                    >
                        {ownershipOptions}
                    </Select>
                </FormField>

                <Grid item xs={12} marginY={4}>
                    <Divider />
                </Grid>

                <FormField
                    id="identifier"
                    title={t("assetDetailsComponent.identifier", "identifier")}
                    control={control}
                >
                    <TextField />
                </FormField>
                <FormField
                    id="nationalPropertyType"
                    title={`${t(
                        "assetDetailsComponent.nationalPropertyType",
                        "national property type"
                    )}`}
                    control={control}
                >
                    <Select
                        error={Boolean(errors.nationalPropertyType)}
                        labelId="nationalPropertyType-label"
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    maxHeight: 200,
                                    width: 50,
                                },
                            },
                        }}
                    >
                        {nationalPropertyTypeOptions}
                    </Select>
                </FormField>
                <FormField
                    id="buildingArea"
                    title={t("assetDetailsComponent.totalArea", "total area")}
                    control={control}
                    rules={{ min: 0 }}
                >
                    <TextField
                        type="number"
                        inputProps={{ min: 0 }}
                        onBlur={(e) => {
                            setValue(
                                "buildingArea",
                                Math.max(0, Number(e.target.value))
                            );
                        }}
                        InputProps={{ endAdornment: "m²" }}
                    />
                </FormField>
                <FormField
                    id="constructionDate"
                    title={t(
                        "assetDetailsComponent.constructionDate",
                        "construction date"
                    )}
                    control={control}
                    tooltip={
                        isConstructionDateDisabled
                            ? t(
                                  "assetDetailsComponent.constructionDateBuildingWarning",
                                  "Construction Date can only be set for Property Type Building"
                              )
                            : undefined
                    }
                    iconType={
                        isConstructionDateDisabled ? "warning" : undefined
                    }
                >
                    <BasicDatePicker
                        disabled={isConstructionDateDisabled}
                        slotProps={{
                            textField: { id: "constructionDate" },
                        }}
                        isClearAllowed
                    />
                </FormField>
                <FormField
                    id="purchaseDate"
                    control={control}
                    title={t(
                        "assetDetailsComponent.purchaseDate",
                        "purchase date"
                    )}
                >
                    <BasicDatePicker
                        slotProps={{
                            textField: { id: "purchaseDate" },
                        }}
                        isClearAllowed
                    />
                </FormField>
                <FormField
                    id="saleDate"
                    control={control}
                    title={t("assetDetailsComponent.saleDate", "sale date")}
                >
                    <BasicDatePicker
                        slotProps={{ textField: { id: "saleDate" } }}
                        isClearAllowed
                    />
                </FormField>

                <Grid item xs={12} marginY={4}>
                    <Divider />
                </Grid>

                <FormField
                    id="address.zip"
                    title={t("common.zipCode", "zip")}
                    control={control}
                >
                    <TextField />
                </FormField>
                <FormField
                    id="address.city"
                    title={t("common.city", "city")}
                    control={control}
                >
                    <TextField />
                </FormField>
                <FormField
                    id="address.street"
                    title={t("common.street", "street")}
                    control={control}
                >
                    <TextField />
                </FormField>
                <FormField
                    id="address.houseNumber"
                    title={t("common.streetNumber", "street number")}
                    control={control}
                >
                    <TextField />
                </FormField>
            </S.Body>

            <S.Footer>
                <Button variant="outlined" onClick={handleCancel}>
                    <Button.Label>
                        {t("common.actions.cancel", "cancel")}
                    </Button.Label>
                </Button>
                <Button
                    type="submit"
                    disabled={disableSave}
                    variant="contained"
                >
                    <Button.Label>
                        {t("common.actions.save", "save")}
                    </Button.Label>
                </Button>
            </S.Footer>
        </S.Form>
    );
};
