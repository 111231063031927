import { t } from "i18next";
import { capitalize, orderBy, startCase, toLower } from "lodash";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
    getLegacyBenchmarkId,
    getLegacyBenchmarkMarkerColor,
} from "components/PortfolioDashboard/Charts/utils";
import { LegacyBenchmarkMarker } from "components/types";
import { LegacyBenchmark, LegacyBenchmarkType } from "graphql-types/graphql";
import {
    useBenchmarkTranslations,
    useRegionTranslation,
    useAssetFilterTranslations,
} from "hooks";

function formatSegmentLabel(
    benchmarkType: LegacyBenchmarkType,
    segment: string
): string {
    return match(benchmarkType)
        .with(LegacyBenchmarkType.BUILDING_AGE, () => `(${segment})`)
        .with(
            LegacyBenchmarkType.BUILDING_SIZE,
            () => `(${segment} ${t("filter.m2", "m²")})`
        )
        .otherwise(() => startCase(toLower(segment.replace(/_/g, " "))));
}

export function useLegacyBenchmarkMarker() {
    const { t } = useTranslation();
    const { getRegionName } = useRegionTranslation();
    const { translations } = useAssetFilterTranslations();
    const { translations: benchmarkTranslations } = useBenchmarkTranslations();

    const convertToLegacyBenchmarkMarker = (
        legacyBenchmarks: LegacyBenchmark[]
    ): LegacyBenchmarkMarker[] => {
        let benchmarks: LegacyBenchmarkMarker[] = [];

        legacyBenchmarks.forEach((legacyBenchmark) => {
            const {
                benchmarkType,
                countryCode,
                segment,
                values,
                numberOfBuildings,
            } = legacyBenchmark;

            const id = getLegacyBenchmarkId(legacyBenchmark);
            const currentBenchmark = benchmarks.find(
                (benchmark) => benchmark?.id === id
            );

            if (!currentBenchmark) {
                const color = getLegacyBenchmarkMarkerColor(benchmarkType);
                const title = `${translations[benchmarkType]} ${capitalize(
                    t("benchmark.singular", "benchmark")
                )}`;

                const benchmarkSegmentLabel =
                    benchmarkType === LegacyBenchmarkType.BBR_PROPERTY_TYPE
                        ? benchmarkTranslations[
                              `${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-${segment}`
                          ]
                        : "";

                const subTitle = `${getRegionName(
                    countryCode ?? ""
                )}, ${formatSegmentLabel(benchmarkType, segment)}`;

                const description = `${t(
                    "benchmark.legacyBenchmarkDescription",
                    "Legacy’s Benchmarks are metrics designed to help property owners assess building emissions and energy performance using specific thresholds"
                )}`;

                benchmarks.push({
                    ...legacyBenchmark,
                    id,
                    color,
                    title,
                    isVisible: false,
                    subTitle,
                    description,
                    values,
                    numberOfBuildings,
                    segmentLabel: benchmarkSegmentLabel,
                });
                return;
            }

            benchmarks = benchmarks.map((benchmark) => {
                if (benchmark.id !== currentBenchmark.id) {
                    return benchmark;
                }

                return {
                    ...benchmark,
                    values: [...benchmark.values, ...values],
                    numberOfBuildings:
                        benchmark.numberOfBuildings + numberOfBuildings,
                };
            });

            benchmarks = orderBy(
                benchmarks,
                ["benchmarkType", "segment"],
                ["desc"]
            );
        });

        return benchmarks;
    };

    return {
        convertToLegacyBenchmarkMarker,
    };
}
