import { ArrowBackRounded } from "@mui/icons-material";
import { Box, IconButton, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IntegrationType } from "graphql-types/graphql";

type Props = {
    title: IntegrationType;
    isEdit: boolean;
    onEditChange: (isEdit: boolean) => void;
    setActiveTab: (tab: IntegrationType | null) => void;
};

export const IntegrationHeader = (props: Props) => {
    const { title, isEdit, onEditChange, setActiveTab } = props;

    const { t } = useTranslation();

    const sectionTitle = `${t(
        "integrationOnboarding.assetIntegrationMapping",
        "Asset Integration Mapping"
    )} | ${
        isEdit
            ? t(
                  "integrationOnboarding.editMeterLocationsTitle",
                  "Edit or remove meters from assets"
              )
            : t(
                  "integrationOnboarding.addMeterLocationsTitle",
                  "Add additional meters to your assets"
              )
    }`;

    return (
        <Box mb={8} sx={{ textAlign: "center" }}>
            <IconButton
                aria-label="back"
                onClick={() => setActiveTab(null)}
                sx={{ position: "absolute", left: 0 }}
            >
                <ArrowBackRounded />
            </IconButton>
            <Typography
                variant="h2"
                sx={{ fontWeight: "bold", textAlign: "center" }}
            >
                {title.toLocaleUpperCase()}
            </Typography>

            <Button
                size="large"
                variant="contained"
                color="info"
                onClick={() => onEditChange(!isEdit)}
                sx={{ position: "absolute", top: 0, right: 0 }}
            >
                {isEdit
                    ? t(
                          "integrationOnboarding.addMeterLocations",
                          "Add Meter Locations"
                      )
                    : t(
                          "integrationOnboarding.editMeterLocations",
                          "Edit Meter Locations"
                      )}
            </Button>

            <Typography variant="h4" sx={{ fontWeight: "bold", mt: 2 }}>
                {sectionTitle}
            </Typography>

            <Typography color="text.secondary" sx={{ mt: 2 }}>
                {t(
                    "integrationOnboarding.assetIntegrationMappingDescription",
                    "Map your assets to your meters to enable data collection and analysis."
                )}
            </Typography>
            <Typography color="text.secondary">
                {!isEdit &&
                    title === IntegrationType.ELECTRALINK &&
                    t(
                        "integrationOnboarding.assetIntegrationMappingDescription2",
                        "In the dropdown, (*) suggests a possible duplicate Electralink meter."
                    )}
            </Typography>
        </Box>
    );
};
