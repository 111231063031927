import { useMutation } from "@apollo/client";

import {
    UpdateAssetIntegrationMutation,
    UpdateAssetIntegrationMutationVariables,
} from "graphql-types/graphql";

import { UPDATE_ASSET_INTEGRATION } from "./drawerFields.query";

export enum UpdateType {
    Note = "Note",
}

export const useUpdateAssetIntegration = () => {
    const [updateAssetIntegration] = useMutation<
        UpdateAssetIntegrationMutation,
        UpdateAssetIntegrationMutationVariables
    >(UPDATE_ASSET_INTEGRATION);

    return { updateAssetIntegration };
};
