import { GridRowParams } from "@mui/x-data-grid";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Table, DYNAMIC_GRID_TABLE_HEIGHT } from "components/Table";
import { AssetFragment } from "graphql-types/graphql";

import { getColumns } from "./asset-list.helpers";
import { useGetAssetsQuery } from "./asset-list.hook";
import { Header } from "./components";

const formatAsset = (assets: AssetFragment[]) => {
    return assets.map((asset) => ({
        id: asset.id,
        ownedArea: asset.ownedArea,
        externalId: asset.externalId,
        displayName: asset.displayName,
    }));
};

export const AssetList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { assets, loading, tablePaginationProps } = useGetAssetsQuery();

    const handleRowClick = useCallback(
        (props: GridRowParams) =>
            navigate(`/location-overview/${props.row.id}`),
        [navigate]
    );

    return (
        <>
            <Header />

            <Table
                isLoading={loading}
                columns={getColumns(t)}
                rows={formatAsset(assets)}
                onRowClick={handleRowClick}
                setCustomHeight={DYNAMIC_GRID_TABLE_HEIGHT}
                paginationMode="server"
                {...tablePaginationProps}
            />
        </>
    );
};
