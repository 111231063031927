import { Box, Button } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { IntegrationType } from "graphql-types/graphql";

import { ConfirmationModal, AssetIntegrationMappingTable } from "./components";
import { useAssetIntegrationMapping } from "./hooks";
import { EditMappingState } from "../types";

type Props = {
    integrationType: IntegrationType;
};

export const AssetIntegrationMapping = (props: Props) => {
    const { integrationType } = props;

    const { t } = useTranslation();

    const { data, loading, handleDelete, handleUpdate } =
        useAssetIntegrationMapping(integrationType);

    const [selectedLocations, setSelectedLocations] =
        useState<EditMappingState>({});

    const [openModal, setOpenModal] = useState<"delete" | "update" | null>(
        null
    );

    const assets = useMemo(() => {
        if (!data) return [];

        const assets = data.getAssets ?? [];

        return assets.map(({ id, displayName, integrations }) => ({
            id,
            displayName,
            integrations: integrations.map((integration) => {
                const label = match(integration.data)
                    .with(
                        {
                            __typename:
                                "IntegrationElectralinkLocationIntegrationData",
                        },
                        (data) => `${data.address} - ${data.partialMpan}`
                    )
                    .with(
                        {
                            __typename:
                                "IntegrationSoftvaerketAssetIntegrationData",
                        },
                        (data) => `${data.address} - ${integration.originId}`
                    )
                    .with(
                        {
                            __typename:
                                "IntegrationEloverblikAssetIntegrationData",
                        },
                        (data) => `${data.address} - ${integration.originId}`
                    )
                    .otherwise(() => "");

                return {
                    label,
                    value: integration.id,
                };
            }),
        }));
    }, [data]);

    const onModalClose = useCallback(
        (operation: "delete" | "update" | null) => {
            match(operation)
                .with("delete", () => handleDelete(selectedLocations))
                .with("update", () => handleUpdate(selectedLocations));

            setOpenModal(null);
        },
        [selectedLocations, handleDelete, handleUpdate]
    );

    const disabled =
        loading ||
        !assets.length ||
        Object.keys(selectedLocations).every(
            (id) => selectedLocations[id].integrations.length === 0
        );

    return (
        <Box>
            <AssetIntegrationMappingTable
                selectAllOnInitialize
                isLoading={loading}
                locations={assets}
                integrationType={integrationType}
                setLocationIntegrations={setSelectedLocations}
            />

            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                    disabled={disabled}
                    variant="contained"
                    onClick={() => setOpenModal("update")}
                >
                    {t("labels.update", "Update")}
                </Button>
                <Button
                    disabled={disabled}
                    sx={{ ml: 2 }}
                    color="error"
                    variant="contained"
                    onClick={() => setOpenModal("delete")}
                >
                    {t("labels.delete", "Delete")}
                </Button>

                {openModal && (
                    <ConfirmationModal
                        open={true}
                        onClose={() => setOpenModal(null)}
                        onConfirm={() => onModalClose(openModal)}
                        operation={openModal}
                        selectedLocations={selectedLocations}
                    />
                )}
            </Box>
        </Box>
    );
};
