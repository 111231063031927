import { gql, useQuery } from "@apollo/client";
import {
    GridRowId,
    GridPaginationModel,
    GridPaginationMeta,
} from "@mui/x-data-grid";
import { useEffect, useMemo, useRef, useState } from "react";

import { GetAssetsQuery, GetAssetsQueryVariables } from "graphql-types/graphql";

const AssetFragment = gql`
    fragment Asset on Location {
        id
        ownedArea
        externalId
        displayName
    }
`;

const GET_ASSETS = gql`
    query GetAssets($paging: CursorPaging!, $filter: LocationFilter!) {
        locations(paging: $paging, filter: $filter) {
            totalCount
            edges {
                node {
                    ...Asset
                }
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
    ${AssetFragment}
`;

const PAGE_SIZE = 20;

/**
 * Based on the pagination guide on the official documentation:
 * https://mui.com/x/react-data-grid/pagination/#cursor-based-pagination
 */
export const useGetAssetsQuery = () => {
    // Pagination model
    const mapPageToNextCursor = useRef<{ [page: number]: GridRowId }>({});
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: PAGE_SIZE,
    });

    // Query
    const { data, previousData, loading } = useQuery<
        GetAssetsQuery,
        GetAssetsQueryVariables
    >(GET_ASSETS, {
        variables: {
            paging: {
                first: PAGE_SIZE,
                after: mapPageToNextCursor.current[paginationModel.page - 1],
            },
            filter: {},
        },
    });

    const { hasNextPage, endCursor } = data?.locations.pageInfo || {};

    // Pagination model change
    const handlePaginationModelChange = (
        newPaginationModel: GridPaginationModel
    ) => {
        // We have the cursor, we can allow the page transition.
        if (
            newPaginationModel.page === 0 ||
            mapPageToNextCursor.current[newPaginationModel.page - 1]
        ) {
            setPaginationModel(newPaginationModel);
        }
    };

    // Row count
    const [rowCount, setRowCount] = useState(0);
    useEffect(() => {
        const currentRowCount = data?.locations.totalCount;

        setRowCount((prevRowCountState) =>
            currentRowCount !== undefined ? currentRowCount : prevRowCountState
        );
    }, [data?.locations.totalCount]);

    // Pagination meta
    const paginationMetaRef = useRef<GridPaginationMeta>();
    const paginationMeta = useMemo(() => {
        if (
            typeof hasNextPage === "boolean" &&
            paginationMetaRef.current?.hasNextPage !== hasNextPage
        ) {
            paginationMetaRef.current = { hasNextPage };
        }
        return paginationMetaRef.current;
    }, [hasNextPage]);

    // Save the cursor for the next page
    useEffect(() => {
        if (!loading && endCursor) {
            mapPageToNextCursor.current[paginationModel.page] = endCursor;
        }
    }, [paginationModel.page, loading, endCursor]);

    const assets =
        data?.locations?.edges.map(({ node }) => node) ??
        previousData?.locations?.edges.map(({ node }) => node) ??
        [];

    return {
        assets,
        loading,
        tablePaginationProps: {
            rowCount,
            pageSizeOptions: [PAGE_SIZE],
            paginationModel,
            paginationMeta,
            onPaginationModelChange: handlePaginationModelChange,
        },
    };
};
