import { useFlags } from "launchdarkly-react-client-sdk";

import { AssetList } from "components/AssetListV2";
import { ActiveBenchmarkProvider } from "components/BenchmarkSelect/BenchmarkSelect";

import AssetListData from "../../components/AssetList/AssetListData";

function AssetListPage() {
    const { usePaginatedAssetList } = useFlags();

    return (
        <ActiveBenchmarkProvider>
            {usePaginatedAssetList ? <AssetList /> : <AssetListData />}
        </ActiveBenchmarkProvider>
    );
}

export default AssetListPage;
