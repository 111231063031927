import styled from "@emotion/styled";
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import { PropsWithChildren } from "react";

export const S = {
    Paper: styled(Paper)(() => ({
        border: "1px solid #e0e0e0",
        borderRadius: "10px",
        elevation: 10,
    })),
    TableContainer: styled(TableContainer)(() => ({
        height: "min-content",
        minHeight: 400,
        maxHeight: "75vh",
    })),
    Table: styled(Table)(({ theme }) => ({
        tableLayout: "fixed",
    })),
    TableBody: styled(TableBody)(({ theme }) => ({})),
    CenterRow: styled(TableRow)(() => ({
        "&:last-child td": { borderBottom: "none" },
    })),
    CenterCell: styled(TableCell)(() => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    })),
    MapTable: (props: PropsWithChildren) => (
        <S.Paper>
            <S.TableContainer>
                <S.Table>
                    <S.TableBody>{props.children}</S.TableBody>
                </S.Table>
            </S.TableContainer>
        </S.Paper>
    ),
    CenterTable: (props: PropsWithChildren) => (
        <S.MapTable>
            <S.CenterRow>
                <S.CenterCell>{props.children}</S.CenterCell>
            </S.CenterRow>
        </S.MapTable>
    ),
    Loading: () => (
        <S.CenterTable>
            <CircularProgress sx={{ mt: 12 }} />
        </S.CenterTable>
    ),
    NoIntegrationRow: (props: PropsWithChildren) => (
        <S.CenterTable>
            <Typography variant="h2" sx={{ py: 12, textAlign: "center" }}>
                {props.children}
            </Typography>
        </S.CenterTable>
    ),
};
