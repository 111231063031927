import { useQuery } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { buildElectralinkMutationPayload } from "containers/IntegrationOnboarding/IntegrationCreationModal/utils/integrationUtils";
import { integrationTypeToGqlRequestMap } from "containers/IntegrationOnboarding/utils";
import { AddMappingState } from "containers/IntegrationOnboardingV2/types";
import {
    ElectralinkLocation,
    GetElectralinkMeterLocationsQuery,
    IntegrationType,
} from "graphql-types/graphql";

import { formatElectralinkLocation } from "./add-electralink-integration.helpers";
import { AssetIntegrationMappingTable } from "../components";
import { useAssetIntegrationMapping } from "../hooks";

const INTEGRATION_TYPE = IntegrationType.ELECTRALINK;

export const AddElectralinkIntegration = () => {
    const { t } = useTranslation();

    const [meterLocations, setMeterLocations] = useState<
        AddMappingState<ElectralinkLocation>
    >({});

    const { meterLocationsQuery } =
        integrationTypeToGqlRequestMap[INTEGRATION_TYPE];

    const { data, loading } = useQuery<GetElectralinkMeterLocationsQuery>(
        meterLocationsQuery,
        { variables: { types: [INTEGRATION_TYPE] } }
    );

    const { handleAdd } = useAssetIntegrationMapping(INTEGRATION_TYPE);

    const onAdd = useCallback(() => {
        let locationCount = 0;
        let integrationsCount = 0;

        const payload = Object.keys(meterLocations).flatMap((locationId) => {
            const { integrations, period } = meterLocations[locationId];

            if (!integrations.length) return [];

            locationCount += 1;
            integrationsCount += integrations.length;

            return integrations.map((data) =>
                buildElectralinkMutationPayload(
                    {
                        assetId: locationId,
                        originId: data.value,
                        startedAt: period.from?.toJSDate() ?? null,
                        endedAt: period.to?.toJSDate() ?? null,
                    },
                    data
                )
            );
        });

        handleAdd(payload, locationCount, integrationsCount);
    }, [handleAdd, meterLocations]);

    const locations = useMemo(() => {
        if (!data) return [];

        const electralinkLocations =
            data.locationsForElectralinkIntegration ?? {};
        const electralinkMeterLocations =
            electralinkLocations.meterLocations ?? [];

        const locations =
            electralinkLocations.initialLocationIntegrationsState.flatMap(
                (initialState) =>
                    formatElectralinkLocation(
                        initialState,
                        electralinkMeterLocations
                    )
            );

        return locations;
    }, [data]);

    const disabled =
        loading ||
        !locations.length ||
        Object.keys(meterLocations).every(
            (id) => meterLocations[id].integrations.length === 0
        );

    return (
        <Box>
            <AssetIntegrationMappingTable
                locations={locations}
                isLoading={loading}
                integrationType={INTEGRATION_TYPE}
                setLocationIntegrations={setMeterLocations}
            />
            <Button
                disabled={disabled}
                onClick={onAdd}
                variant="contained"
                color="success"
                sx={{ mt: 2 }}
            >
                {t(
                    "integrationOnboarding.addLocationIntegrationsButtonLabel",
                    "Add Location Integrations"
                )}
            </Button>
        </Box>
    );
};
