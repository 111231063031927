import {
    Box,
    Container,
    List,
    ListItem,
    Paper,
    useTheme,
    Typography,
    styled,
    TextField,
    Button,
} from "@mui/material";
import { useEffect, useState } from "react";

import { AssetIntegrationMappingTable } from "containers/IntegrationOnboardingV2/AssetIntegrationMapping";
import { IntegrationType } from "graphql-types/graphql";

import ColorBarMock from "./ColorBar.mock";
import { TableBrickMock } from "./TableBrick.mock";
import NumberTextBrick from "../../components/Brick/NumberTextBrick";

const ColorTile = styled(Paper)(({ theme }) => ({
    width: "150px",
    height: "150px",
    margin: "5px 15px 5px 0px",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
}));

const ColorTileDarkText = styled(Paper)(({ theme }) => ({
    width: "150px",
    height: "150px",
    margin: "5px 15px 5px 0px",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.black,
}));

const Typo = styled(Typography)(() => ({
    margin: "5px 15px 5px 0px",
}));

const ComponentSection = () => (
    <Container sx={{ mb: 20 }}>
        <Typography variant="h2">Components</Typography>
        <List>
            <ListItem sx={{ display: "flex", gap: 4 }}>
                <Typo variant="h3">Buttons</Typo>
                <Button variant="contained" color="primary">
                    Primary
                </Button>
                <Button variant="contained" color="secondary">
                    Secondary
                </Button>
                <Button variant="contained" color="primary" disabled>
                    Disabled
                </Button>
            </ListItem>
            <ListItem sx={{ display: "flex", gap: 4 }}>
                <Typo variant="h3">Text Fields</Typo>
                <TextField label="Default" />
                <TextField label="Disabled" disabled />
                <TextField label="Error" error />
            </ListItem>
            <ListItem sx={{ display: "flex", gap: 4 }}>
                <Typo variant="h3">NumberTextBrick</Typo>
                <NumberTextBrick value="230" label="Active" />
                <NumberTextBrick
                    value="230"
                    label="Expired"
                    secondaryLabel="(97%)"
                />
                <NumberTextBrick
                    value="7"
                    label="Expired"
                    secondaryLabel="(3%)"
                />
            </ListItem>
            <ListItem sx={{ display: "flex", gap: 4 }}>
                <Typo variant="h3">TableBrick</Typo>
                <TableBrickMock />
            </ListItem>
            <ListItem sx={{ display: "flex", gap: 4 }}>
                <Typo variant="h3">Color Bar</Typo>
                <ColorBarMock />
            </ListItem>
        </List>
    </Container>
);

const generateLocations = (count: number) => {
    const locations = [];
    for (let i = 0; i < count; i++) {
        locations.push({
            id: `Location ${i}`,
            displayName: `Location ${i} `,
            integrations:
                i === 0
                    ? []
                    : Array.from(
                          { length: Math.floor(Math.random() * 90) + 10 },
                          (_, j) => ({
                              id: `Integration ${j}`,
                              locationId: `Location ${i}`,
                              label: `Integration ${j} - hashedValue${j}`,
                              value: `Integration ${j}`,
                          })
                      ),
        });
    }
    return locations;
};

const TableSection = () => {
    const [selected, setSelected] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);

    return (
        <Container>
            <Typography variant="h2">Tables</Typography>
            <List>
                <Typography variant="h3">Table 1</Typography>
                <AssetIntegrationMappingTable
                    locations={generateLocations(40)}
                    integrationType={IntegrationType.DEXMA}
                    isLoading={loading}
                    setLocationIntegrations={(selected) =>
                        setSelected(selected)
                    }
                />
            </List>

            <Typography variant="h3">Selected Values</Typography>
            <List>
                {Object.entries(selected).map(([key, value]) => (
                    <ListItem key={key}>
                        <Typography mr={12}>{key}</Typography>
                        <Typography>
                            {JSON.stringify(value, null, 2)}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export const ThemePage = () => {
    const theme = useTheme();
    return (
        <>
            <Container
                style={{ display: "flex", justifyContent: "space-evenly" }}
            >
                <Box>
                    <Typo variant="h2">Colors</Typo>
                    <Typo variant="h3">General</Typo>

                    <List>
                        <ListItem>
                            <ColorTile
                                style={{
                                    background: theme.palette.primary.main,
                                }}
                            >
                                {theme.palette.primary.main}
                            </ColorTile>
                            Primary Main
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{
                                    background: theme.palette.primary.light,
                                }}
                            >
                                {theme.palette.primary.light}
                            </ColorTile>
                            Primary Light
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{
                                    background: theme.palette.secondary.main,
                                }}
                            >
                                {theme.palette.secondary.main}
                            </ColorTile>
                            Nav Bar Color, Tile 1 Color
                        </ListItem>
                        <ListItem>
                            <ColorTileDarkText
                                style={{
                                    background:
                                        theme.palette.background.default,
                                }}
                            >
                                {theme.palette.background.default}
                            </ColorTileDarkText>
                            Background Color,Tile 2 Color
                        </ListItem>
                        <ListItem>
                            <ColorTileDarkText
                                style={{ background: theme.palette.grey[100] }}
                            >
                                {theme.palette.grey[100]}
                            </ColorTileDarkText>
                            Tile 3 Color
                        </ListItem>
                    </List>
                    <Typo variant="h3">Verticals</Typo>
                    <List>
                        <ListItem>
                            <ColorTile
                                style={{ background: theme.palette.heat.main }}
                            >
                                {theme.palette.heat.main}
                            </ColorTile>
                            Heat Vertical, Below Benchmark text
                        </ListItem>
                        <ListItem>
                            <ColorTileDarkText
                                style={{ background: theme.palette.heat.light }}
                            >
                                {theme.palette.heat.light}
                            </ColorTileDarkText>
                            Heat Vertical Light
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{
                                    background: theme.palette.electricity.main,
                                }}
                            >
                                {theme.palette.electricity.main}
                            </ColorTile>
                            Electricity Vertical
                        </ListItem>
                        <ListItem>
                            <ColorTileDarkText
                                style={{
                                    background: theme.palette.electricity.light,
                                }}
                            >
                                {theme.palette.electricity.light}
                            </ColorTileDarkText>
                            Electricity Vertical Light
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{ background: theme.palette.water.main }}
                            >
                                {theme.palette.water.main}
                            </ColorTile>
                            Water Vertical
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{
                                    background: theme.palette.water.light,
                                }}
                            >
                                {theme.palette.water.light}
                            </ColorTile>
                            Water Vertical Light
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{
                                    background: theme.palette.transport.main,
                                }}
                            >
                                {theme.palette.transport.main}
                            </ColorTile>
                            Transport Vertical
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{
                                    background: theme.palette.transport.light,
                                }}
                            >
                                {theme.palette.transport.light}
                            </ColorTile>
                            Transport Vertical Light
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{ background: theme.palette.waste.main }}
                            >
                                {theme.palette.waste.main}
                            </ColorTile>
                            Waste Vertical
                        </ListItem>
                        <ListItem>
                            <ColorTileDarkText
                                style={{
                                    background: theme.palette.waste.light,
                                }}
                            >
                                {theme.palette.waste.light}
                            </ColorTileDarkText>
                            Waste Vertical Light
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{
                                    background: theme.palette.materials.main,
                                }}
                            >
                                {theme.palette.materials.main}
                            </ColorTile>
                            Materials Vertical
                        </ListItem>
                        <ListItem>
                            <ColorTileDarkText
                                style={{
                                    background: theme.palette.materials.light,
                                }}
                            >
                                {theme.palette.materials.light}
                            </ColorTileDarkText>
                            Materials Vertical Light
                        </ListItem>
                    </List>
                    <Typo variant="h3">Status Colors</Typo>
                    <List>
                        <ListItem>
                            <ColorTile
                                style={{ background: theme.palette.error.main }}
                            >
                                {theme.palette.error.main}
                            </ColorTile>
                            Error
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{
                                    background: theme.palette.warning.main,
                                }}
                            >
                                {theme.palette.warning.main}
                            </ColorTile>
                            Warning
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{
                                    background: theme.palette.success.main,
                                }}
                            >
                                {theme.palette.success.main}
                            </ColorTile>
                            Success
                        </ListItem>
                        <ListItem>
                            <ColorTile
                                style={{ background: theme.palette.info.main }}
                            >
                                {theme.palette.info.main}
                            </ColorTile>
                            Info
                        </ListItem>
                    </List>
                </Box>
                <Box>
                    <Typo variant="h2">Typography</Typo>
                    Basefont size is 16 px (1rem)
                    <List>
                        <ListItem>
                            <Typo variant="h1" color="textSecondary">
                                Heading 1
                            </Typo>
                            Vertical Title Headings
                        </ListItem>
                        <ListItem>
                            <Typo variant="h2">Heading 2</Typo>
                            Company name
                        </ListItem>
                        <ListItem>
                            <Typo variant="h3">Heading 3</Typo>
                            Vertical body text
                        </ListItem>
                        <ListItem>
                            <Typo variant="h4">Heading 4</Typo>
                            Page title, Onboarding task, Manual Input headings
                        </ListItem>
                        <ListItem>
                            <Typo variant="h5">Heading 5</Typo>
                            Nav 2 text
                        </ListItem>
                        <ListItem>
                            <Typo variant="h5" style={{ fontWeight: 700 }}>
                                Heading 5 (Bold)
                            </Typo>
                            Graph filter text (Active)
                        </ListItem>
                        <ListItem>
                            <Typo variant="h5" color="textSecondary">
                                Heading 5
                            </Typo>
                            Graph filter text (Inactive)
                        </ListItem>
                        <ListItem>
                            <Typo variant="h6">Heading 6</Typo>
                            Graph axis text
                        </ListItem>
                        <ListItem>
                            <Typo variant="body1">Body1 </Typo>
                            Nav 1 text,Paragraph text, Input text, Card titles,
                            Overlay text
                        </ListItem>
                        <ListItem>
                            <Typo variant="body1" color="textSecondary">
                                Body 1
                            </Typo>
                            Default Input field text
                        </ListItem>
                        <ListItem>
                            <Typo style={{ fontWeight: 300, fontSize: "60px" }}>
                                Not in theme
                            </Typo>
                            Card Numeric Values
                        </ListItem>
                    </List>
                </Box>
            </Container>
            <ComponentSection />
            <TableSection />
        </>
    );
};
