import { Paper, styled } from "@mui/material";

export const S = {
    Item: styled(Paper)(({ theme }) => ({
        padding: theme.spacing(4),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    })),
};
