import auth from "./auth";

export const downloadFile = (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.href = url;
    a.setAttribute("download", fileName);

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
};

export type FetchOptions = {
    method?: RequestInit["method"];
    body?: RequestInit["body"];
};

export const downloadBuffer = (
    endpoint: string,
    fileName: string,
    options?: FetchOptions
) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = auth.getToken();
    const url = `${apiUrl}/${endpoint}`;

    if (!endpoint || !token) {
        return;
    }

    try {
        return fetch(url, {
            method: options?.method,
            body: options?.body,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.blob())
            .then((blob) => downloadFile(blob, fileName));
    } catch (error) {
        throw new Error(
            `An error occurred trying to download the report: ${error}`
        );
    }
};

export const downloadCSV = (data: string[][], fileName: string) => {
    const csv = data
        .map((row) =>
            row
                .map((cell) => {
                    if (cell.search(/("|,|\n)/g) >= 0) {
                        return `"${cell}"`;
                    }

                    return cell;
                })
                .join(",")
        )
        .join("\n");

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    downloadFile(blob, fileName);
};
