import { useQuery } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { integrationTypeToGqlRequestMap } from "containers/IntegrationOnboarding/utils";
import { AddMappingState } from "containers/IntegrationOnboardingV2/types";
import {
    GetSoftvaerketMeterLocationsQuery,
    IntegrationType,
    Maybe,
    SoftvaerketMeter,
} from "graphql-types/graphql";
import { DASH_SEPARATOR } from "utils";

import { AssetIntegrationMappingTable } from "../components";
import { useAssetIntegrationMapping } from "../hooks";

const INTEGRATION_TYPE = IntegrationType.SOFTVAERKET;

const formatMeter = (
    initialState: GetSoftvaerketMeterLocationsQuery["locationsForSoftvaerket"]["initialLocationIntegrationsState"][0],
    meters: SoftvaerketMeter[]
) => {
    const { customerLocation, meterLocations } = initialState;

    const integrations = meters.map((meter) => {
        const { address, identifier, supplierId } = meter;

        return {
            id: meter.id,
            value: identifier,
            selected: meterLocations && meters.some((m) => m.id === meter.id),
            label: `${address} - ${identifier} (${meter.supplierName})`,
            address,
            supplierId,
        };
    });

    const displayName = customerLocation.displayName ?? DASH_SEPARATOR;

    return {
        id: customerLocation.id,
        displayName,
        integrations,
    };
};

export const AddSoftvaerketIntegration = () => {
    const { t } = useTranslation();

    const [meterLocations, setMeterLocations] = useState<
        AddMappingState<{
            id: string;
            address: string;
            supplierId: Maybe<string> | undefined;
        }>
    >({});

    const { meterLocationsQuery } =
        integrationTypeToGqlRequestMap[INTEGRATION_TYPE];

    const { data, loading } =
        useQuery<GetSoftvaerketMeterLocationsQuery>(meterLocationsQuery);

    const { handleAdd } = useAssetIntegrationMapping(INTEGRATION_TYPE);

    const onAdd = useCallback(() => {
        let assetCount = 0;
        let integrationsCount = 0;

        const payload = Object.keys(meterLocations).flatMap((assetId) => {
            const { integrations, period } = meterLocations[assetId];

            if (!integrations.length) return [];

            assetCount += 1;
            integrationsCount += integrations.length;

            return integrations.map((data) => ({
                type: IntegrationType.SOFTVAERKET,
                assetId,
                data: JSON.stringify({
                    meterId: data.id,
                    address: data.address,
                    supplierId: data.supplierId,
                }),
                originId: data.value,
                startedAt: period.from?.toJSDate() ?? null,
                endedAt: period.to?.toJSDate() ?? null,
            }));
        });

        handleAdd(payload, assetCount, integrationsCount);
    }, [handleAdd, meterLocations]);

    const assets = useMemo(() => {
        if (!data) return [];

        const { locationsForSoftvaerket } = data;

        const initialState =
            locationsForSoftvaerket?.initialLocationIntegrationsState ?? [];

        const assets =
            initialState.flatMap((state) =>
                formatMeter(state, locationsForSoftvaerket.meterLocations)
            ) ?? [];

        return assets;
    }, [data]);

    const disabled =
        loading ||
        !assets.length ||
        Object.keys(meterLocations).every(
            (id) => meterLocations[id].integrations.length === 0
        );

    return (
        <Box>
            <AssetIntegrationMappingTable
                locations={assets}
                isLoading={loading}
                integrationType={INTEGRATION_TYPE}
                setLocationIntegrations={setMeterLocations}
            />
            <Button
                disabled={disabled}
                onClick={onAdd}
                variant="contained"
                color="success"
                sx={{ mt: 2 }}
            >
                {t(
                    "integrationOnboarding.addLocationIntegrationsButtonLabel",
                    "Add Location Integrations"
                )}
            </Button>
        </Box>
    );
};
