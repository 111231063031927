import { BusinessCenter, AccountBox, Handyman } from "@mui/icons-material";
import {
    Box,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Typography,
} from "@mui/material";
import { TFunction } from "i18next";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CompanyProfile from "components/CompanyProfile/CompanyProfile";
import UserProfile from "components/UserProfile/UserProfile";
import { useOrganizationContext } from "context";
import { convertToRem } from "utils/maths";

import { SettingsIntegrationsTab } from "./integrationsTab/SettingsIntegrationsTab";

enum SettingsItemType {
    Profile = "PROFILE",
    Company = "COMPANY",
    Integration = "INTEGRATION",
}

const SettingsListItem = styled(ListItemButton)(({ theme }) => ({
    background: theme.palette.common.white,
    fontSize: convertToRem(14),
    padding: theme.spacing(4, 5),
    marginBottom: theme.spacing(3),

    "&.Mui-selected > *": {
        color: theme.palette.primary.main,
    },
}));

const Header = () => {
    const organization = useOrganizationContext();
    const { t } = useTranslation();

    return (
        <Box sx={{ mb: 12 }}>
            <Typography variant="h4">
                {t("settings.title", "KONTOINDSTILLINGER")}
            </Typography>
            <Typography variant="h1">{organization?.name ?? ""}</Typography>
        </Box>
    );
};

const getItemTitle = (item: SettingsItemType, t: TFunction) => {
    switch (item) {
        case SettingsItemType.Profile:
            return t("settings.type.profile", "Profile");
        case SettingsItemType.Company:
            return t("settings.type.company", "Company");
        case SettingsItemType.Integration:
            return t("settings.type.integration", "Integration");
        default:
            return "";
    }
};

type ItemIconProps = {
    item: SettingsItemType;
};

const ItemIcon = (props: ItemIconProps) => {
    const { item } = props;
    switch (item) {
        case SettingsItemType.Profile:
            return <AccountBox />;
        case SettingsItemType.Company:
            return <BusinessCenter />;
        case SettingsItemType.Integration:
            return <Handyman />;
        default:
            return null;
    }
};

type MenuItemProps = {
    item: SettingsItemType;
    active: boolean;
    setActive: (item: SettingsItemType) => any;
};

const MenuItem = (props: MenuItemProps) => {
    const { setActive, item, active } = props;
    const { t } = useTranslation();

    const handleClick = useCallback(() => setActive(item), [setActive, item]);

    return (
        <SettingsListItem selected={active} onClick={handleClick}>
            <ListItemIcon>
                <ItemIcon item={item} />
            </ListItemIcon>
            <ListItemText primary={getItemTitle(item, t)} />
        </SettingsListItem>
    );
};

type ActiveSettingsPanelProps = {
    activeItem: SettingsItemType;
};

const ActiveSettingsPanel = (props: ActiveSettingsPanelProps) => {
    const { activeItem } = props;

    const { t } = useTranslation();

    const SettingsPanel = useCallback(() => {
        switch (activeItem) {
            case SettingsItemType.Profile:
                return <UserProfile />;
            case SettingsItemType.Company:
                return <CompanyProfile />;
            case SettingsItemType.Integration:
                return <SettingsIntegrationsTab />;
            default:
                return t(
                    "settings.notFound",
                    "Ingen indstillinger fundet for konto"
                );
        }
    }, [activeItem, t]);

    const settingTitle = useMemo(
        () => getItemTitle(activeItem, t),
        [activeItem, t]
    );

    return (
        <Box
            sx={(theme) => ({
                background: theme.palette.background.paper,
                height: "100%",
                padding: theme.spacing(12.5, 12.5),
            })}
        >
            <Typography variant="h1" color="textSecondary" mb={8}>
                {settingTitle}
            </Typography>
            <SettingsPanel />
        </Box>
    );
};

const SettingsPage = () => {
    const [activeItem, setActive] = useState(SettingsItemType.Profile);

    const menuItems = [
        SettingsItemType.Profile,
        SettingsItemType.Company,
        SettingsItemType.Integration,
    ];

    return (
        <Box>
            <Header />
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <List disablePadding={true}>
                        {menuItems.map((item) => (
                            <MenuItem
                                key={item}
                                item={item}
                                setActive={setActive}
                                active={activeItem === item}
                            />
                        ))}
                    </List>
                </Grid>
                <Grid item xs={9}>
                    <ActiveSettingsPanel activeItem={activeItem} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettingsPage;
