import { Modal, Paper, Box, styled } from "@mui/material";

export const S = {
    Modal: styled(Modal)(() => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    })),

    ModalBody: styled(Paper)(({ theme }) => ({
        width: theme.spacing(270),
        height: theme.spacing(164),
    })),

    Header: styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.grey[300]}`,
        height: theme.spacing(18),
        width: "100%",
        paddingLeft: theme.spacing(8),
    })),

    Content: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(4),
        padding: theme.spacing(8),
    })),
};
