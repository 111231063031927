import { ElectricalServices, Handyman } from "@mui/icons-material";
import { Button, ButtonGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IntegrationType } from "graphql-types/graphql";
import { getIntegrationTypeTranslation } from "utils/translations";

import { S } from "./integration-link.styles";

export const ENABLED_INTEGRATIONS = [
    IntegrationType.ELECTRALINK,
    IntegrationType.SOFTVAERKET,
    IntegrationType.ELOVERBLIK,
];

type Props = {
    isActive: boolean;
    integration: IntegrationType;
    onClick: (tab: IntegrationType, updateMeters: boolean) => void;
};

export const IntegrationLink = (props: Props) => {
    const { integration, isActive, onClick } = props;

    const { t } = useTranslation();

    const Icon = !isActive ? ElectricalServices : Handyman;

    return (
        <S.Item>
            <Typography variant="h3">
                {getIntegrationTypeTranslation(integration).toUpperCase()}
            </Typography>

            <ButtonGroup>
                <Button
                    size="medium"
                    startIcon={<Icon />}
                    disabled={!ENABLED_INTEGRATIONS.includes(integration)}
                    onClick={() => onClick(integration, true)}
                    color={isActive ? "success" : "primary"}
                    variant="outlined"
                >
                    {isActive
                        ? t("integrationOnboarding.configure", "Configure")
                        : t("integrationOnboarding.connect", "Connect")}
                </Button>

                {isActive && integration === IntegrationType.ELOVERBLIK && (
                    <Button
                        size="medium"
                        startIcon={<ElectricalServices />}
                        onClick={() => onClick(integration, false)}
                        color="error"
                        variant="outlined"
                    >
                        {t("labels.update", "Update")}
                    </Button>
                )}
            </ButtonGroup>
        </S.Item>
    );
};
