import { gql } from "@apollo/client";

export const GET_LOCATION_INTEGRATION = gql`
    query GetLocationIntegrations($type: IntegrationType!) {
        getAssets {
            id
            displayName
            integrations(filter: { type: { eq: $type } }) {
                id
                originId
                startedAt
                endedAt
                data {
                    ... on IntegrationElectralinkLocationIntegrationData {
                        postCode
                        partialMpan
                        address
                    }

                    ... on IntegrationSoftvaerketAssetIntegrationData {
                        address
                    }

                    ... on IntegrationEloverblikAssetIntegrationData {
                        address
                        meteringPointId
                    }
                }
            }
        }
    }
`;
