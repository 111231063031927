import { DocumentNode } from "graphql";

import {
    SupportedIntegrationForOnboarding,
    IntegrationType,
} from "graphql-types/graphql";

import {
    GET_DEXMA_METER_LOCATIONS_QUERY,
    GET_ELECTRALINK_METER_LOCATIONS_QUERY,
    GET_ElOVERBLIK_METER_LOCATIONS_QUERY,
    GET_ENTOLABS_METER_LOCATIONS_QUERY,
    GET_HOFOR_METER_LOCATIONS_QUERY,
    GET_OIS_METER_LOCATIONS_QUERY,
    GET_SOFTVAERKET_METER_LOCATIONS_QUERY,
    GET_TECHEM_METER_LOCATIONS_QUERY,
    GET_VITANI_METER_LOCATIONS_QUERY,
} from "./graphql/locationIntegrationOnboardingQuery";

type IntegrationTypeToGqlRequestMap = Record<
    SupportedIntegrationForOnboarding,
    { meterLocationsQuery: DocumentNode }
>;

export const integrationTypeToGqlRequestMap: IntegrationTypeToGqlRequestMap = {
    [IntegrationType.DEXMA]: {
        meterLocationsQuery: GET_DEXMA_METER_LOCATIONS_QUERY,
    },
    [IntegrationType.ELECTRALINK]: {
        meterLocationsQuery: GET_ELECTRALINK_METER_LOCATIONS_QUERY,
    },
    [IntegrationType.TECHEM]: {
        meterLocationsQuery: GET_TECHEM_METER_LOCATIONS_QUERY,
    },
    [IntegrationType.VITANI]: {
        meterLocationsQuery: GET_VITANI_METER_LOCATIONS_QUERY,
    },
    [IntegrationType.HOFOR]: {
        meterLocationsQuery: GET_HOFOR_METER_LOCATIONS_QUERY,
    },
    [IntegrationType.OIS]: {
        meterLocationsQuery: GET_OIS_METER_LOCATIONS_QUERY,
    },
    [IntegrationType.ENTOLABS]: {
        meterLocationsQuery: GET_ENTOLABS_METER_LOCATIONS_QUERY,
    },
    [IntegrationType.SOFTVAERKET]: {
        meterLocationsQuery: GET_SOFTVAERKET_METER_LOCATIONS_QUERY,
    },
    [IntegrationType.ELOVERBLIK]: {
        meterLocationsQuery: GET_ElOVERBLIK_METER_LOCATIONS_QUERY,
    },
};

export const UNSUPPORTED_INTEGRATION_TYPE = "Unsupported integration type";
